import { createEvent, createStore } from 'effector';
import * as R from 'ramda';


export enum NotificationType {
  JOIN_MASKS = 'JOIN_MASKS',
}

export const $notificationType = createStore<NotificationType | null>(null);
export const setNotification = createEvent<NotificationType | null>();

$notificationType.on(setNotification, R.nthArg(1));
