import { createEvent, createStore } from "effector";


export interface HintI {
  elementId: string;
  text: JSX.Element | string;
  extendedHintType?: string;
  arrowPosition: "right" | "top" | "bottom";
  alignPosition?: "center" | "flex-end" | "flex-start" | "right";
  isTutorial?: boolean;
  index?: string;
}

export const hintArr: HintI[] = [
  {
    elementId: 'definitions_warning',
    text: 'Individual definitions disabled. Type common definition.',
    arrowPosition: 'top',
  },
  {
    elementId: "identify_button",
    text: "create/edit mask indicating object you want to define",
    extendedHintType: "indicate",
    arrowPosition: "right",
  },
  {
    elementId: "left-bar__icon1",
    text: "Frame anything to include in the object mask. Redo to override.",
    extendedHintType: "frame to add",
    arrowPosition: "right",
  },
  {
    elementId: "left-bar__icon2",
    text: "Hover and click anything to add to the mask",
    extendedHintType: "point to add",
    arrowPosition: "right",
  },
  {
    elementId: "left-bar__icon3",
    text: "Hover and click anything to exclude from the mask",
    extendedHintType: "point to exclude",
    arrowPosition: "right",
  },
  {
    elementId: "left-bar__icon5",
    text: "Brush",
    extendedHintType: "brush",
    arrowPosition: "right",
    alignPosition: "flex-end",
  },
  {
    elementId: "left-bar__icon6",
    text: "Eraser",
    extendedHintType: "eraser",
    arrowPosition: "right",
    alignPosition: "flex-end",
  },
  {
    elementId: "select",
    text: "Select masks",
    extendedHintType: "select",
    arrowPosition: "right",
  },
  {
    elementId: "move",
    text: "Hand tool",
    extendedHintType: "hand tool",
    arrowPosition: "right",
  },
  {
    elementId: "undo_redo",
    text: "Undo/redo",
    extendedHintType: "undo/redo",
    arrowPosition: "right",
  },
  {
    elementId: "create_group",
    text: "Create a group of similar objects to describe together",
    extendedHintType: "create group",
    arrowPosition: "bottom",
  },
  {
    elementId: "edit_group",
    text: "Group to define together",
    extendedHintType: "edit group",
    arrowPosition: "bottom",
  },
  {
    elementId: "ungroup",
    text: "Ungroup to define separately",
    extendedHintType: "ungroup",
    arrowPosition: "bottom",
  },
  {
    elementId: "join",
    text: "Join masks",
    extendedHintType: "join",
    arrowPosition: "bottom",
    // arrowPosition: "top",
    alignPosition: "center",
  },
  {
    elementId: "delete",
    text: "Select and delete",
    arrowPosition: "bottom",
  },
  {
    elementId: "undefine",
    text: "Discard all object definitions",
    extendedHintType: "undefine",
    arrowPosition: "bottom",
  },
  {
    elementId: "undefined-switcher",
    text: "Show undefined objects",
    extendedHintType: "undefined-switcher",
    arrowPosition: "top",
  },
  {
    elementId: "original_switcher",
    text: "Hide masks",
    extendedHintType: "hide masks",
    arrowPosition: "top",
  },
  {
    elementId: 'reselect',
    text: (<>Restore the previous selection. <br />Memory is limited to the last active selection. </>),
    arrowPosition: 'top',
  },
  {
    elementId: "zoom_in",
    text: "Zoom in",
    arrowPosition: "top",
  },
  {
    elementId: "zoom_out",
    text: "Zoom out",
    arrowPosition: "top",
  },

  {
    elementId: "zoom_fit",
    text: "Zoom to fit in the viewport",
    arrowPosition: "top",
  },
  {
    elementId: "research_demo",
    text: "Click to start from the beginning",
    arrowPosition: "bottom",
  },
  {
    elementId: "list__head_add",
    text: "Attach anything",
    extendedHintType: "attach",
    arrowPosition: "bottom",
  },
  {
    elementId: "checkbox_selected",
    text: "Multiselect",
    arrowPosition: "bottom",
  },

  {
    elementId: "checkbox",
    text: "Select all",
    arrowPosition: "bottom",
  },
  {
    elementId: "checkbox_unselect",
    text: "Remove selection",
    arrowPosition: "bottom",
  },
  {
    elementId: "header_home",
    text: "Home",
    arrowPosition: "bottom",
  },
  {
    elementId: "home_create-info",
    text: "Prototype is a file that stores the image uploaded to render, the data on how the image is divided into objects, and descriptions of those objects, including text notes and attachments.",
    arrowPosition: "top",
  },
  {
    elementId: "account_photo-info",
    text: "Some information",
    arrowPosition: "bottom",
  },
  {
    elementId: "balance-info",
    text: "Some information",
    arrowPosition: "top",
  },
  {
    elementId: "scenery-info",
    text: "If your interior has a window or your building has surroundings, select the description from the dropdown that best matches the view.",
    arrowPosition: "top",
  },
  {
    elementId: "help-scroll_down",
    text: "Scroll down",
    arrowPosition: "top",
  },
  {
    elementId: "help",
    text: "Help",
    arrowPosition: "top",
  },
  {
    elementId: "collapse",
    text: "Collapse",
    arrowPosition: "top",
    alignPosition: "right",
  },
  {
    elementId: "launch_tutorial-info_edit",
    text: "Finish edit mode to run tutorial",
    arrowPosition: "top",
  },
  {
    elementId: "launch_tutorial-info",
    text: "Add some segments (min 3) at your sketch to see the tutorial",
    arrowPosition: "top",
  },
  {
    elementId: "open_prototype-icon",
    text: "Open prototype",
    arrowPosition: "bottom",
  },
  {
    elementId: "upload__register",
    text: "Account settings",
    arrowPosition: "bottom",
  },
  {
    elementId: "segment_alarm-icon_",
    text: "Include or discard description to finish group creation",
    arrowPosition: "top",
  },
  {
    elementId: "segment_include-bttn_",
    text: "Include object's description in the group",
    arrowPosition: "top",
  },
  {
    elementId: "attachment_segment_",
    text: "",
    arrowPosition: "top",
  },
  {
    elementId: "attachment_group-segment-element_",
    text: "",
    arrowPosition: "top",
  },
  {
    elementId: "attachment_group_",
    text: "",
    arrowPosition: "top",
  },
  {
    elementId: "attachment_editing-group_",
    text: "",
    arrowPosition: "top",
  },
  {
    elementId: "color_circle_",
    text: "Tune mask color",
    arrowPosition: "top",
    // extendedHintType: "color circle",
  },
  {
    elementId: "color_circle-group_segment_",
    text: "Tune mask color",
    arrowPosition: "top",
    // extendedHintType: "color circle",
  },
  //
];
export const $hint = createStore<HintI | null>(null);

export const setHint = createEvent<{
  id: string;
  index?: string;
  text?: string;
} | null>();
export const setTutorialHint = createEvent<string | null>();

$hint
  .on(setHint, (_, payload) => {
    if (!payload) return null;
    let updatedHintItem = null;

    const hintItem = hintArr.find((item) => item.elementId === payload.id);

    if (hintItem) {
      if (payload.id === "checkbox_selected") {
        hintItem.elementId = "checkbox";
      } else if (payload.id === "launch_tutorial-info_edit") {
        hintItem.elementId = "launch_tutorial-info";
      }
      if (payload.index) {
        hintItem.index = payload.index;
      }
      hintItem.text = payload.text || hintItem.text;
      updatedHintItem = hintItem;
    }

    return updatedHintItem;
  })
  .on(setTutorialHint, (_, id) => {
    const hintItem = hintArr.find((item) => item.elementId === id);
    if (hintItem) return { ...hintItem, isTutorial: true };
    return null;
  });
