import config from 'config';


export async function inpaint({ base_image, mask_image, attachment }: { base_image: File; mask_image: File; attachment: string }): Promise<string> {
    const formData = new FormData();

    formData.append('steps', '20');
    formData.append('base_image', base_image);
    formData.append('mask_image', mask_image);
    formData.append('reference_image_name', attachment);

    const res = await fetch('https://sam.quarters-dev.site/inpaint/generate-inpainting-image/', {
        method: 'POST',
        body: formData,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.task_id;
}

export async function textInpaint({ base_image, mask_image, prompt }: { base_image: File; mask_image: File; prompt: string }): Promise<string> {
    const formData = new FormData();

    formData.append('steps', '20');
    formData.append('prompt', prompt);
    formData.append('base_image', base_image);
    formData.append('mask_image', mask_image);

    const res = await fetch('https://sam.quarters-dev.site/inpaint/generate-inpainting-text/', {
        method: 'POST',
        body: formData,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.task_id;
}

export async function modelInpaint({ base_image, mask_image, modelName, trigger_word }: { base_image: File; mask_image: File; modelName: string, trigger_word: string }): Promise<string> {
    const formData = new FormData();

    formData.append('model_name', modelName);
    formData.append('base_image', base_image);
    formData.append('mask_image', mask_image);
    formData.append('prompt', trigger_word);

    const res = await fetch('https://sam.quarters-dev.site/inpaint/generate-inpainting-model/', {
        method: 'POST',
        body: formData,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.task_id;
}

export async function cancelInpaint(id: string) {
    const res = await fetch('https://sam.quarters-dev.site/inpaint/cancel-task/' + id, {
        method: 'POST',
    });

    if (!res.ok) throw new Error('Unknown error.');
    await res.json();
}

export async function inpaintCheckStatus(id: string): Promise<string> {
    const res = await fetch('https://sam.quarters-dev.site/inpaint/task-status/' + id);

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.status;
}

export async function baseRender({ file, prompt }: { file: File, prompt: string }): Promise<string> {
    const formData = new FormData();

    formData.append('steps', '20');
    formData.append('prompt', prompt);
    formData.append('control_image', file);

    const res = await fetch('https://sam.quarters-dev.site/baserender/generate-image/', {
        method: 'POST',
        body: formData,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.task_id;
}

export async function baseRenderCheckStatus(id: string): Promise<string> {
    const res = await fetch('https://sam.quarters-dev.site/baserender/task-status/' + id);

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.status;
}

export async function recognize(file: File): Promise<string> {
    const body = new FormData();
    body.append('image', file);

    const res = await fetch(`${config.serverUrl}/upload`, {
        method: 'POST',
        body,
    });
    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.id;
}

export type Mask = {
    name: number;
}

export type Status = 'QUEUE' | 'PROCESS' | 'END' | 'ERROR';

export async function loadMask(id: string): Promise<Array<Mask> | { status: Status; queueSize: number }> {
    const res = await fetch(`${config.serverUrl}/get-item/${id}`);

    if (!res.ok) throw new Error('Bad request.');

    const data: Array<Mask> = await res.json();

    return data;
}

export async function createSketch(data: string, masks: Array<{ mask: Blob, name: number }>, input: File, id: string) {
    const body = new FormData();
    masks.forEach(mask => body.append('mask', mask.mask, mask.name + '.png'));
    body.append('data', data);
    body.append('input', input, id + '.png');

    const res = await fetch(`${config.backendUrl}/api/sketch/${id}`, {
        method: 'POST',
        body,
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}


export async function saveSketch(data: string, id: string) {
    const res = await fetch(`${config.backendUrl}/api/sketch/${id}`, {
        method: 'PUT',
        body: data,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function savePrototype(prototype: Blob, sketchId: string) {
    const body = new FormData();
    body.append('prototype', prototype, sketchId + '.png');
    const res = await fetch(`${config.backendUrl}/api/sketch/prototype`, {
        method: 'POST',
        body,
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function saveMask(mask: Blob, name: number, sketchId: string) {
    const body = new FormData();
    body.append('mask', mask, name + '.png');
    const res = await fetch(`${config.backendUrl}/api/sketch/mask/${sketchId}`, {
        method: 'POST',
        body,
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function deleteSketch(id: string) {
    const res = await fetch(`${config.backendUrl}/api/sketch/${id}`, {
        method: 'DELETE',
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function updateSketchName(data: { sketchId: string, name: string }) {
    const res = await fetch(`${config.backendUrl}/api/sketch/${data.sketchId}/name`, {
        method: 'PATCH',
        body: JSON.stringify({ name: data.name }),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

type ResponseData<T> = {
    error: boolean;
    result: T;
};

export type Sketch = {
    id: string;
    date: string;
    name: string;
    lastModified: string;
};

export async function loadSketches() {
    const res = await fetch(config.backendUrl + '/api/sketch', {
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Bad request.');

    const data: ResponseData<Array<Sketch>> = await res.json();
    if (data.error) throw new Error('Unknown error.');

    return data.result;
}

export type FullSketch = {
    id: string;
    name: string;
    date: Date;
    lastModified: Date;
    userId: string;
    config: string;
    deleted: boolean;
};

export async function loadSketch(id: string) {
    const res = await fetch(config.backendUrl + '/api/sketch/' + id, {
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Bad request.');

    const data: ResponseData<FullSketch> = await res.json();
    if (data.error) throw new Error('Unknown error.');

    return data.result;
}

export enum RenderState {
    CREATED = 'CREATED',
    RENDERED = 'RENDERED',
    ERROR = 'ERROR',
}

export type Render = {
    id: string;
    state: RenderState;
};

export async function loadRenders(sketchId: string) {
    const res = await fetch(config.backendUrl + '/api/renders/' + sketchId, {
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Bad request.');

    const data: ResponseData<Array<Render>> = await res.json();
    if (data.error) throw new Error('Unknown error.');

    return data.result;
}

export async function copySketch(id: string): Promise<Sketch> {
    const date = new Date().toLocaleString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).replace(',', '');

    const res = await fetch(`${config.backendUrl}/api/sketch/${id}/copy`, {
        method: 'POST',
        body: JSON.stringify({ date }),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');

    return answer.result;
}

export async function saveRender(sketchId: string, prototype: Blob): Promise<string> {
    const body = new FormData();
    body.append('render', prototype);
    body.append('sketchId', sketchId);
    const res = await fetch(`${config.backendUrl}/api/render`, {
        method: 'PUT',
        body,
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
    return answer.result;
}
