import { useCallback, useEffect, useState } from 'react';
import config from 'config';
import './Approve.scss';


enum ApproveStatus {
    NONE,
    APPLIED,
    APPROVED,
}

export default function Approve() {
    const [users, setUsers] = useState<Array<{ id: string; email: string; researchDemoApproveStatus: ApproveStatus }>>([]);

    const load = useCallback(async () => {
        const response = await fetch(`${config.backendUrl}/api/user`, {
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Unknown error.');
        const data = await response.json();
        if (data.error) throw new Error(data.result);
        setUsers(data.result.filter((user: any) => user.email));
    }, []);

    const approve = useCallback(async (id: string) => {
        const response = await fetch(`${config.backendUrl}/api/user/approve-research-demo/${id}`, {
            method: 'PUT',
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Failed to approve user.');
        const data = await response.json();
        if (data.error) throw new Error(data.result);
        load();
    }, [load]);

    useEffect(() => void load(), [load]);

    return (
        <div className="approve">
            <div className="approve__table">
                <table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Approved</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.email}>
                                <td>{user.email}</td>
                                <td>{user.researchDemoApproveStatus}</td>
                                <td>
                                    <button onClick={() => approve(user.id)}>Approve</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}