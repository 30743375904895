import { useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from 'Components/Button';
import { NotificationType, setNotification } from 'entities/notification';
import { useManager } from 'entities/sketch/Manager';
import './JoinMaskNotification.scss';


export default function JoinMaskNotification() {
    const manager = useManager('self');
    const [checked, setChecked] = useState<boolean>(false);

    const check = useCallback(() => setChecked(prev => {
        localStorage.setItem(NotificationType.JOIN_MASKS, JSON.stringify(prev));
        return !prev;
    }), []);

    const ok = useCallback(() => {
        manager.combineSegments();
        setNotification(null);
    }, [manager]);

    return (
        <div className="join-mask-notification__wrapper">
            <div className="join-mask-notification">
                <div className="join-mask-notification__exit" onClick={() => setNotification(null)} />
                <div className="join-mask-notification__title-line">
                    <div className="join-mask-notification__title-icon" />
                    <div className="join-mask-notification__title">Join masks</div>
                </div>
                <div className="join-mask-notification__text">When you join object masks, resultant mask inherit the definition of the masked object you select first. </div>
                <div className="join-mask-notification__button-line">
                    <div className="join-mask-notification__buttons">
                        <Button color="white" size="medium" onClick={() => ok()}>OK</Button>
                        <Button color="white" size="medium" onClick={() => setNotification(null)}>CANCEL</Button>
                    </div>
                    <div className="join-mask-notification__checkbox-form">
                        <div className={classNames('join-mask-notification__checkbox', { 'join-mask-notification__checkbox_checked': checked })} onClick={() => check()} />
                        <div className="join-mask-notification__checkbox-label">Never show again</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
