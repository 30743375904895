import { produce } from 'immer';
import classNames from 'classnames';
import SelectForm from 'Components/SelectForm';
import ColorForm from 'Components/ColorForm';
import { useUploader } from 'entities/sketch/Uploader';
import { ManagerInitStep, useInitiator, useManager } from 'entities/sketch/Manager';
import './Qualities.scss';


const materialDiversity = ['Uniform', 'Diverse'];
const prevailingMaterials = ['Natural', 'Artificial'];
const dominantTextures = ['Plain', 'Active'];
const dominantSurfaces = ['Polished', 'Matte'];
const detailIntegration = ['Integrated', 'Utilitarian'];
const paletteDiversity = ['Monochrome', 'Colorful'];
const paletteTemperature = ['Cold', 'Warm'];


export default function Qualities() {
    const { uploader } = useManager('self');
    const checked = useUploader('checked');
    const form = useUploader('state');
    const initStep = useInitiator('initStep');

    return (
        <div className={classNames({ qualities: true, disabled: initStep === ManagerInitStep.PROTOTYPING })}>
            <div className="qualities__col">
                <div className="qualities__forms">
                    <div className="qualities__form">
                        <div>HOW DIVERSE ARE THE DESIGN MATERIALS?</div>
                        <SelectForm options={materialDiversity} state={form.materialDiversity} setState={value => uploader.state = produce(form, form => void (form.materialDiversity = value))} checked={checked} placeholder="Select" />
                    </div>
                    <div className="qualities__form">
                        <div>WHICH MATERIALS PREVAIL?</div>
                        <SelectForm options={prevailingMaterials} state={form.prevailingMaterials} setState={value => uploader.state = produce(form, form => void (form.prevailingMaterials = value))} checked={checked} placeholder="Select" />
                    </div>
                    <div className="qualities__form">
                        <div>WHICH MATERIAL TEXTURES DOMINATE IN THE DESIGN?</div>
                        <SelectForm options={dominantTextures} state={form.dominantTextures} setState={value => uploader.state = produce(form, form => void (form.dominantTextures = value))} checked={checked} placeholder="Select" />
                    </div>
                    <div className="qualities__form">
                        <div>WHICH SURFACES DOMINATE IN THE DESIGN?</div>
                        <SelectForm options={dominantSurfaces} state={form.dominantSurfaces} setState={value => uploader.state = produce(form, form => void (form.dominantSurfaces = value))} checked={checked} placeholder="Select" />
                    </div>
                    <div className="qualities__form">
                        <div>HOW STRONG ARE DETAILS INTEGRATED IN THE DESIGN?</div>
                        <SelectForm options={detailIntegration} state={form.detailIntegration} setState={value => uploader.state = produce(form, form => void (form.detailIntegration = value))} checked={checked} placeholder="Select" />
                    </div>
                </div>
            </div>
            <div />
            <div className="qualities__col">
                <div className="qualities__forms">
                    <div className="qualities__form">
                        <div>HOW DIVERSE IS THE DESIGN COLOR PALETTE?</div>
                        <SelectForm options={paletteDiversity} state={form.paletteDiversity} setState={value => uploader.state = produce(form, form => void (form.paletteDiversity = value))} checked={checked} placeholder="Select" />
                    </div>
                    <div className="qualities__form">
                        <div>CHOOSE DESIGN PALETTE TEMPERATURE</div>
                        <SelectForm options={paletteTemperature} state={form.paletteTemperature} setState={value => uploader.state = produce(form, form => void (form.paletteTemperature = value))} checked={checked} placeholder="Select" />
                    </div>
                    <div className="qualities__form">
                        <div>SELECT PRIMARY TONE</div>
                        <ColorForm state={form.primaryTone} setState={value => uploader.state = produce(form, form => void (form.primaryTone = value))} checked={checked} placeholder="Select" />
                    </div>
                    <div className="qualities__form">
                        <div>SELECT SECONDARY TONE</div>
                        <ColorForm state={form.secondaryTone} setState={value => uploader.state = produce(form, form => void (form.secondaryTone = value))} checked={checked} placeholder="Select" />
                    </div>
                </div>
            </div>
        </div>
    );
}
