import * as constants from './constants';
import * as shaders from './shaders';
import { Box } from '../Manager';
/* import { delay } from '../utils'; */


type Color = [number, number, number, number];

export enum SpriteState {
    REGULAR = 'REGULAR',
    BLACK_AND_WHITE = 'BLACK_AND_WHITE',
    HIDE = 'HIDE',
}

interface Sprite {
    mask: Uint8Array;
    texture: WebGLTexture | null;
    renderColor: Color;
    selectOutlineWidth: number;
    hoverOutlineWidth: number;
    state: SpriteState;
    maskBox: Array<number>;
}

type ProgramData = {
    program: WebGLProgram;
    vao: WebGLVertexArrayObject;
};

export default class Engine {
    private width = 0;
    private height = 0;
    private readonly sprites: Array<Sprite> = [];
    private picture: WebGLTexture | null = null;
    private gl: WebGL2RenderingContext | null = null;
    private pictureProgramData: ProgramData | null = null;
    private circleProgramData: ProgramData | null = null;
    private programData: ProgramData | null = null;
    private outlineProgramData: ProgramData | null = null;
    private rectangleProgramData: ProgramData | null = null;
    private lineProgramData: ProgramData | null = null;
    private shadowProgramData: ProgramData | null = null;
    private ext: undefined;
    private renderTime: number = 0;
    private renderCount: number = 0;
    cursor: null | [number, number] = null;
    highlightedSprite: Sprite | null = null;
    cursorRadius = 20;
    frame: Box | null = null;
    frameColor: Color = [0.5, 0.5, 0.5, 0.8];
    frameDashed: boolean = false;
    scale = 1;
    translate = [0, 0];

    constructor(sprites: Array<Sprite>) {
        this.sprites = sprites;
    }

    public async init(gl: WebGL2RenderingContext) {
        this.gl = gl;
/*         this.ext = this.gl.getExtension("EXT_disjoint_timer_query_webgl2"); */
        this.gl.enable(this.gl.BLEND);
        this.gl.blendFunc(this.gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        this.createPictureProgram();
        this.createCircleProgram();
        this.createProgram();
        this.createOutlineProgram();
        this.createRectangleProgram();
        this.createLineProgram();
        this.createShadowProgram();

        //setInterval(() => console.log(`drawArrays занял ${this.renderTime / 1e6 / this.renderCount} мс`), 1000);
    }

    private createPictureProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.vertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.pictureFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('Program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.pictureProgramData = { program, vao };
    }

    private createCircleProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.circleVertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.circleFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('outline program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.circleProgramData = { program, vao };
    }

    private createProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.vertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.fragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('Program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.programData = { program, vao };
    }

    private createOutlineProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.vertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.outlineFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('outline program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.outlineProgramData = { program, vao };
    }

    private createLineProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.lineVertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.lineFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('outline program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.lineProgramData = { program, vao };
    }

    private createRectangleProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.rectangleVertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.rectangleFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('outline program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.rectangleProgramData = { program, vao };
    }

    private createShadowProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.vertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.shadowFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('Program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.shadowProgramData = { program, vao };
    }

    public calcScaleMatrix2() {
        if (!this.gl) throw new Error('gl is null.');
        const scale = [this.scale, this.scale];

        const canvasRatio = this.gl.canvas.width / this.gl.canvas.height;
        const pictureRatio = this.width / this.height;

        if (canvasRatio > pictureRatio) scale[0] = pictureRatio / canvasRatio * this.scale;
        else scale[1] = canvasRatio / pictureRatio * this.scale;

        return scale;
    }

    private calcScaleMatrix8() {
        if (!this.gl) throw new Error('gl is null.');
        const scaleValue = this.calcScaleMatrix2();

        return scale(vertices, scaleValue);
    }

    public calcGlobalPosition() {
        const scalePosition = this.calcScaleMatrix8();

        const localPosition = [
            -1, -1,
            -1, 1,
            1, -1,
            1, 1,
        ];

        return translate(multiply(localPosition, scalePosition), this.translate);
    }

    public draw() {
        if (!this.gl) throw new Error('gl is null.');

        this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

        const scalePosition = this.calcScaleMatrix8();

        if (this.picture) {
            if (!this.pictureProgramData) throw new Error('Ppicture program is null');
            this.gl.useProgram(this.pictureProgramData.program);
            this.gl.activeTexture(this.gl.TEXTURE0);
            this.gl.bindTexture(this.gl.TEXTURE_2D, this.picture);

            const uTextureLocation = this.gl.getUniformLocation(this.pictureProgramData.program, 'uTexture');
            this.gl.uniform1i(uTextureLocation, 0);

            this.gl.bindVertexArray(this.pictureProgramData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const position = this.calcGlobalPosition();

            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(position), this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.pictureProgramData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            const texCoordBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);

            this.gl.bufferData(this.gl.ARRAY_BUFFER, constants.texCoords, this.gl.STATIC_DRAW);
            const aTexCoordLocation = this.gl.getAttribLocation(this.pictureProgramData.program, 'aTexCoord');
            this.gl.enableVertexAttribArray(aTexCoordLocation);
            this.gl.vertexAttribPointer(aTexCoordLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.bindVertexArray(null);
        }


        let sprites: Array<Sprite> = [];

        if (this.highlightedSprite) {
            sprites = [this.highlightedSprite];
        } else {
            sprites = this.sprites;
        }

        if (this.highlightedSprite) {
            if (!this.shadowProgramData) throw new Error('Picture program is null');
            this.gl.useProgram(this.shadowProgramData.program);

            this.gl.bindVertexArray(this.shadowProgramData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const position = this.calcGlobalPosition();

            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(position), this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.shadowProgramData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            const texCoordBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);

            this.gl.bufferData(this.gl.ARRAY_BUFFER, constants.texCoords, this.gl.STATIC_DRAW);
            const aTexCoordLocation = this.gl.getAttribLocation(this.shadowProgramData.program, 'aTexCoord');
            this.gl.enableVertexAttribArray(aTexCoordLocation);
            this.gl.vertexAttribPointer(aTexCoordLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.bindVertexArray(null);
        }

/*         const query = this.gl.createQuery();
        if (!query) throw new Error('Query is null.');
        this.gl.beginQuery(this.ext.TIME_ELAPSED_EXT, query); */
        sprites.forEach(({ texture, renderColor, state, maskBox }) => {
            if (state === SpriteState.HIDE) return;
            if (!this.gl) throw new Error('gl is null.');
            if (!this.programData) throw new Error('Program is null');

            this.gl.useProgram(this.programData.program);
            this.gl.activeTexture(this.gl.TEXTURE0);
            this.gl.bindTexture(this.gl.TEXTURE_2D, texture);

            const uTextureLocation = this.gl.getUniformLocation(this.programData.program, 'uMask');
            this.gl.uniform1i(uTextureLocation, 0);

            const uColorLocation = this.gl.getUniformLocation(this.programData.program, 'uColor');
            this.gl.uniform4fv(uColorLocation, new Float32Array(renderColor));

            const uCanvasSize = this.gl.getUniformLocation(this.programData.program, 'uCanvasSize');
            this.gl.uniform2i(uCanvasSize, this.width, this.height);

            const uTexSize = this.gl.getUniformLocation(this.programData.program, 'uTexSize');
            const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
            this.gl.uniform2f(uTexSize, side, side);

            this.gl.bindVertexArray(this.programData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const x0_norm = (maskBox[0] / this.width) * 2 - 1;
            const y0_norm = 1 - (maskBox[1] / this.height) * 2;
            const x1_norm = (maskBox[2] / this.width) * 2 - 1;
            const y1_norm = 1 - (maskBox[3] / this.height) * 2;

            const localPosition = [
                x0_norm, y0_norm,
                x0_norm, y1_norm,
                x1_norm, y0_norm,
                x1_norm, y1_norm,
            ];

            const position = translate(multiply(localPosition, scalePosition), this.translate);

            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(position), this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.programData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            const texCoordBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);

            const x0_tex = maskBox[0] / this.width;
            const y0_tex = maskBox[1] / this.height;
            const x1_tex = maskBox[2] / this.width;
            const y1_tex = maskBox[3] / this.height;

            const texCoords = [
                x0_tex, y0_tex,
                x0_tex, y1_tex,
                x1_tex, y0_tex,
                x1_tex, y1_tex,
            ];

            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(texCoords), this.gl.STATIC_DRAW);
            const aTexCoordLocation = this.gl.getAttribLocation(this.programData.program, 'aTexCoord');
            this.gl.enableVertexAttribArray(aTexCoordLocation);
            this.gl.vertexAttribPointer(aTexCoordLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.deleteBuffer(texCoordBuffer);
            this.gl.bindVertexArray(null);
        });
/*         this.gl.endQuery(this.ext.TIME_ELAPSED_EXT);
        delay(100).then(() => {
            if (!this.gl) throw new Error('gl is null.');
            if (this.gl.getQueryParameter(query, this.gl.QUERY_RESULT_AVAILABLE)) {
                this.renderTime += this.gl.getQueryParameter(query, this.gl.QUERY_RESULT);
                this.renderCount++;
                this.gl.deleteQuery(query);
            }
        }); */

        sprites.forEach(({ texture, selectOutlineWidth, hoverOutlineWidth, maskBox: rawMaskBox }, index) => {
            const outline = Math.max(selectOutlineWidth, hoverOutlineWidth) / Math.sqrt(this.scale);
            if (!outline) return;

            if (!this.gl) throw new Error('gl is null.');
            if (!this.outlineProgramData) throw new Error('Outline program is null');

            if (!texture) throw new Error(`Texture ${index} doesn't exist.`);

            this.gl.useProgram(this.outlineProgramData.program);
            this.gl.activeTexture(this.gl.TEXTURE0);
            this.gl.bindTexture(this.gl.TEXTURE_2D, texture);

            const uTextureLocation = this.gl.getUniformLocation(this.outlineProgramData.program, 'uMask');
            this.gl.uniform1i(uTextureLocation, 0);

            const uOutlineColorLocation = this.gl.getUniformLocation(this.outlineProgramData.program, 'uOutlineColor');
            this.gl.uniform4fv(uOutlineColorLocation, new Float32Array([0, 0.35, 1, 1]));

            const uWidthLocation = this.gl.getUniformLocation(this.outlineProgramData.program, 'uWidth');
            this.gl.uniform1i(uWidthLocation, outline);

            const uCanvasSize = this.gl.getUniformLocation(this.outlineProgramData.program, 'uCanvasSize');
            this.gl.uniform2i(uCanvasSize, this.width, this.height);

            const uTexSize = this.gl.getUniformLocation(this.outlineProgramData.program, 'uTexSize');
            const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
            this.gl.uniform2f(uTexSize, side, side);

            this.gl.bindVertexArray(this.outlineProgramData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const maskBox = [
                Math.max(rawMaskBox[0] - outline - 3, 0),
                Math.max(rawMaskBox[1] - outline - 3, 0),
                Math.min(rawMaskBox[2] + outline + 5, this.width),
                Math.min(rawMaskBox[3] + outline + 5, this.height)
            ];

            const x0_norm = maskBox[0] / this.width * 2 - 1;
            const y0_norm = 1 - maskBox[1] / this.height * 2;
            const x1_norm = maskBox[2] / this.width * 2 - 1;
            const y1_norm = 1 - maskBox[3] / this.height * 2;

            const localPosition = [
                x0_norm, y0_norm,
                x0_norm, y1_norm,
                x1_norm, y0_norm,
                x1_norm, y1_norm,
            ];

            const position = translate(multiply(localPosition, scalePosition), this.translate);

            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(position), this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.outlineProgramData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            const texCoordBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);

            const x0_tex = maskBox[0] / this.width;
            const y0_tex = maskBox[1] / this.height;
            const x1_tex = maskBox[2] / this.width;
            const y1_tex = maskBox[3] / this.height;

            const texCoords = [
                x0_tex, y0_tex,
                x0_tex, y1_tex,
                x1_tex, y0_tex,
                x1_tex, y1_tex,
            ];

            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(texCoords), this.gl.STATIC_DRAW);
            const aTexCoordLocation = this.gl.getAttribLocation(this.outlineProgramData.program, 'aTexCoord');
            this.gl.enableVertexAttribArray(aTexCoordLocation);
            this.gl.vertexAttribPointer(aTexCoordLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.bindVertexArray(this.outlineProgramData.vao);
            this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.deleteBuffer(texCoordBuffer);
            this.gl.bindVertexArray(null);
        });

        if (this.frame && !this.frameDashed) {
            if (!this.rectangleProgramData) throw new Error('Rectangle program is null');

            this.gl.useProgram(this.rectangleProgramData.program);

            const uColor = this.gl.getUniformLocation(this.rectangleProgramData.program, 'uColor');
            this.gl.uniform4fv(uColor, new Float32Array(this.frameColor));

            this.gl.bindVertexArray(this.rectangleProgramData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const { width, height } = this.gl.canvas;

            const position = [
                this.frame.value[0], this.frame.value[3],
                this.frame.value[2], this.frame.value[3],
                this.frame.value[2], this.frame.value[1],
                this.frame.value[0], this.frame.value[1],
            ]
                .map((value, index) => index % 2 ? 1 - value * 2 / height : value * 2 / width - 1);


            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(position), this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.rectangleProgramData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.drawArrays(this.gl.LINE_LOOP, 0, 4);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.bindVertexArray(null);
        }

        if (this.frame && this.frameDashed) {
            if (!this.lineProgramData) throw new Error('Line program is null');

            this.gl.useProgram(this.lineProgramData.program);

            const uColor = this.gl.getUniformLocation(this.lineProgramData.program, 'uColor');
            this.gl.uniform4fv(uColor, new Float32Array(this.frameColor));

            this.gl.bindVertexArray(this.lineProgramData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const { width, height } = this.gl.canvas;

            const position = [
                this.frame.value[0], this.frame.value[3],
                this.frame.value[2], this.frame.value[3],
                this.frame.value[2], this.frame.value[1],
                this.frame.value[0], this.frame.value[1],
            ]
                .map((value, index) => index % 2 ? 1 - value * 2 / height : value * 2 / width - 1);

            const lenght = 6;

            const dashedRectangleVertices = generateDashedRectangle(position[0], position[7], position[2], position[1], width, height, lenght);
            this.gl.bufferData(this.gl.ARRAY_BUFFER, dashedRectangleVertices, this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.lineProgramData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.drawArrays(this.gl.LINES, 0, dashedRectangleVertices.length / 2);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.bindVertexArray(null);
        }

        if (this.cursor) {
            if (!this.circleProgramData) throw new Error('Circle program is null');

            this.gl.useProgram(this.circleProgramData.program);

            this.gl.bindVertexArray(this.circleProgramData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const circleVertices = this.createCircleVertices(this.cursor[0], this.cursor[1], this.cursorRadius, 36);

            this.gl.bufferData(this.gl.ARRAY_BUFFER, circleVertices, this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.circleProgramData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.drawArrays(this.gl.LINE_STRIP, 0, circleVertices.length / 2);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.bindVertexArray(null);
        }
    }

    private createCircleVertices(centerX: number, centerY: number, radius: number, numSegments: number) {
        if (!this.gl) throw new Error('gl is null.');

        const vertices = [];
        for (let i = 0; i <= numSegments; i++) {
            const angle = (i / numSegments) * 2 * Math.PI;
            const x = centerX / this.gl.canvas.width * 2 - 1 + Math.cos(angle) * radius / this.gl.canvas.width;
            const y = 1 - centerY / this.gl.canvas.height * 2 + Math.sin(angle) * radius / this.gl.canvas.height;
            vertices.push(x, y);
        }
        return new Float32Array(vertices);
    }

    public updateSprite(sprite: Sprite) {
        if (!this.gl) throw new Error('gl is null.');

        this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.gl.texSubImage2D(this.gl.TEXTURE_2D, 0, 0, 0, side, side, this.gl.RGBA, this.gl.UNSIGNED_BYTE, sprite.mask);
    }

    public deleteSprite(sprite: Sprite) {
        if (!this.gl) throw new Error('gl is null.');

        this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);
        this.gl.deleteTexture(sprite.texture);
        this.gl.bindTexture(this.gl.TEXTURE_2D, null);
    }

    public addSprite(sprite: Sprite) {
        if (!this.gl) throw new Error('gl is null.');

        sprite.texture = this.gl.createTexture();

        this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));

        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, side, side, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, sprite.mask);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
    }

    public updatePicture(picture: HTMLImageElement | null) {
        if (!this.gl) throw new Error('gl is null.');

        this.gl.bindTexture(this.gl.TEXTURE_2D, this.picture);
        this.gl.deleteTexture(this.picture);
        this.gl.bindTexture(this.gl.TEXTURE_2D, null);

        if (!picture) return this.picture = null;

        this.width = picture.naturalWidth;
        this.height = picture.naturalHeight;

        this.picture = this.gl.createTexture();

        this.gl.bindTexture(this.gl.TEXTURE_2D, this.picture);
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, picture);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
    }
}

const vertices = [
    1, 1,
    1, 1,
    1, 1,
    1, 1,
];

function scale(matrix: Array<number>, scale: Array<number>) {
    return Array.from({ length: matrix.length }, (_, index) => {
        if (index % 2) return matrix[index] * scale[1];
        else return matrix[index] * scale[0];
    });
}

function translate(matrix: Array<number>, translate: Array<number>) {
    return Array.from({ length: matrix.length }, (_, index) => {
        if (index % 2) return matrix[index] + translate[1];
        else return matrix[index] + translate[0];
    });
}

function multiply(first: Array<number>, second: Array<number>) {
    if (first.length !== second.length) throw new Error('Lengths are different.');
    return Array.from({ length: first.length }, (_, index) => first[index] * second[index]);
}

function generateDashedRectangle(x1: number, y1: number, x2: number, y2: number, width: number, height: number, lenght: number) {
    const vertices: Array<number> = [];

    const topLenght = Math.abs(x1 - x2) / 2 * width;
    const leftLenght = Math.abs(y1 - y2) / 2 * height;

    const perimeter = topLenght * 2 + leftLenght * 2;
    const k = Math.round(perimeter / lenght / 2) * 2;
    lenght = perimeter / k;

    let x = x1;
    let y = y1;

    const delta = Date.now() % (lenght * 300) / 150;

    let dash = delta > lenght;
    let currentLenght = delta > lenght ? delta - lenght : delta;

    while (x > x2) {
        let lNorm = currentLenght / width * 2;
        if (x - lNorm < x2) {
            currentLenght = (lNorm - x + x2) * width / 2;
            lNorm = x - x2;
        } else currentLenght = lenght;
        if (dash) vertices.push(x, y, x - lNorm, y);
        x -= lNorm;
        if (currentLenght === lenght) dash = !dash;
    }
    if (y1 > y2)
        while (y > y2) {
            let lNorm = currentLenght / height * 2;
            if (y - lNorm < y2) {
                currentLenght = (lNorm - y + y2) * height / 2;
                lNorm = y - y2;
            } else currentLenght = lenght;
            if (dash) vertices.push(x, y, x, y - lNorm);
            y -= lNorm;
            if (currentLenght === lenght) dash = !dash;
        }
    else
        while (y < y2) {
            let lNorm = currentLenght / height * 2;
            if (y + lNorm > y2) {
                currentLenght = (lNorm - y2 + y) * height / 2;
                lNorm = y2 - y;
            } else currentLenght = lenght;
            if (dash) vertices.push(x, y, x, y + lNorm);
            y += lNorm;
            if (currentLenght === lenght) dash = !dash;
        }
    while (x < x1) {
        let lNorm = currentLenght / width * 2;
        if (x + lNorm > x1) {
            currentLenght = (lNorm - x1 + x) * width / 2;
            lNorm = x1 - x;
        } else currentLenght = lenght;
        if (dash) vertices.push(x, y, x + lNorm, y);
        x += lNorm;
        if (currentLenght === lenght) dash = !dash;
    }
    if (y1 > y2)
        while (y < y1) {
            let lNorm = currentLenght / height * 2;
            if (y + lNorm > y1) {
                currentLenght = (lNorm - y1 + y) * height / 2;
                lNorm = y1 - y;
            } else currentLenght = lenght;
            if (dash) vertices.push(x, y, x, y + lNorm);
            y += lNorm;
            if (currentLenght === lenght) dash = !dash;
        }
    else
        while (y > y1) {
            let lNorm = currentLenght / height * 2;
            if (y - lNorm < y1) {
                currentLenght = (lNorm - y + y1) * height / 2;
                lNorm = y - y1;
            } else currentLenght = lenght;
            if (dash) vertices.push(x, y, x, y - lNorm);
            y -= lNorm;
            if (currentLenght === lenght) dash = !dash;
        }

    return new Float32Array(vertices);
}
