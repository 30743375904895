import { useEffect } from 'react';


type Options = {
    modifier?: 'ctrlKey';
    condition?: boolean;
    notInput?: boolean;
};

export function useHotKey(key: string, fn: () => any, options: Options = {}) {
    useEffect(() => {
        function checkKey(event: KeyboardEvent) {
            if (options.notInput) {
                if (document.activeElement instanceof HTMLInputElement) return;
                if (document.activeElement instanceof HTMLTextAreaElement) return;
            }
            if (options.modifier && !event[options.modifier]) return;
            if (options.condition !== undefined && !options.condition) return;
            if (event.key !== key) return;
            fn();
        }
        document.addEventListener('keydown', checkKey);

        return () => document.removeEventListener('keydown', checkKey);
    }, [key, fn, options]);
}
