import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { $models, addModel, loadModelsFx } from 'entities/library';
import { Model } from 'services/library';
import './Generator.scss';


export default function Generator() {
    const models = useStore($models);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<File | null>(null);
    const [processing, setProcessing] = useState(false);
    const [mode, setMode] = useState('create');
    const [trainedModel, setTrainedModel] = useState<string | null>(null);
    const [deleteInput, setDeleteInput] = useState('');

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) throw new Error('Files not exist.');
        const file = files[0];
        if (!file) throw new Error('File not exist.');
        setFile(file);
    }, []);

    const onChangePreview = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) throw new Error('Files not exist.');
        const file = files[0];
        if (!file) throw new Error('File not exist.');
        setPreview(file);
    }, []);

    const send = useCallback(async () => {
        if (!file) throw new Error('File not exist.');
        if (!preview) throw new Error('Preview not exist.');
        setProcessing(true);
        const id = crypto.randomUUID();
        const formData = new FormData();
        formData.append('model_name', id);
        const createModelResponse = await fetch('https://sam.quarters-dev.site/training/create-model/', {
            method: 'POST',
            body: formData,
        });
        const createModelResult = await createModelResponse.json();
        await fetch("https://api.logsnag.com/v1/log", {
            method: "POST",
            headers: {
                "Authorization": "Bearer 9a5243a2f261ebf00c8f4e1447e849e1",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                project: "generator",
                channel: "generator",
                event: "create-model",
                description: JSON.stringify({
                    responseBody: createModelResult,
                    headers: Object.fromEntries(createModelResponse.headers.entries()),
                }),
                tags: {
                    status: createModelResponse.status,
                    success: createModelResponse.ok,
                },
            }),
        });
        formData.append('dataset', file);
        const res = await fetch('https://sam.quarters-dev.site/training/start-training/', {
            method: 'POST',
            body: formData,
        });
        const data: { status: 'created'; training_id: string; trigger_word: string } = await res.json();
        await fetch("https://api.logsnag.com/v1/log", {
            method: "POST",
            headers: {
                "Authorization": "Bearer 9a5243a2f261ebf00c8f4e1447e849e1",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                project: "generator",
                channel: "generator",
                event: "start-training",
                description: JSON.stringify({
                    responseBody: data,
                    headers: Object.fromEntries(res.headers.entries()),
                }),
                tags: {
                    status: res.status,
                    success: res.ok,
                },
            }),
        });
        const backendForm = new FormData();
        backendForm.append('training_id', data.training_id);
        backendForm.append('trigger_word', data.trigger_word);
        backendForm.append('render_preview', preview, id + '.jpg');
        backendForm.append('description', description);
        backendForm.append('name', name);
        backendForm.append('id', id);
        const backendRes = await fetch('https://quarters-dev.site/api/trained-model', {
            method: 'POST',
            body: backendForm,
        });
        const { result } = await backendRes.json();
        addModel(result);
        setProcessing(false);
    }, [name, description, file, preview]);

    const selectModel = useCallback((model: Model) => {
        setDescription(model.description);
        setName(model.name);
        setTrainedModel(model.id);
        setMode('edit');
    }, []);

    const selectCreateMode = useCallback(() => {
        setMode('create');
        setTrainedModel(null);
        setDescription('');
        setName('');
        setFile(null);
    }, []);

    const update = useCallback(async () => {
        if (!trainedModel) return;
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        if (preview) formData.append('render_preview', preview, trainedModel + '.jpg');
        await fetch('https://quarters-dev.site/api/trained-model/' + trainedModel, {
            method: 'PUT',
            body: formData,
        });
        loadModelsFx();
    }, [description, name, preview, trainedModel]);

    const deleteTrainedModel = useCallback(async () => {
        if (!trainedModel) return;
        await fetch('https://quarters-dev.site/api/trained-model/' + trainedModel, {
            method: 'DELETE',
        });
        const formData = new FormData();
        formData.append('model_name', trainedModel);
        await fetch('https://sam.quarters-dev.site/training/delete-model/', {
            method: 'POST',
            body: formData,
        });
        selectCreateMode();
        loadModelsFx();
    }, [trainedModel, selectCreateMode]);

    useEffect(() => setDeleteInput(''), [trainedModel, name, description, preview]);

    return (
        <div className="generator">
            <div className="generator__left">
                <div className="generator__switcher">
                    <div className={classNames('generator__mode', { generator__mode_selected: mode === 'create' })} onClick={() => selectCreateMode()}>CREATE</div>
                    <div className={classNames('generator__mode', { generator__mode_selected: mode === 'edit' })}>EDIT</div>
                </div>
                {mode === 'create' && <>
                    <label>Model name</label>
                    <input value={name} onChange={e => setName(e.target.value)} />
                    <label>Description</label>
                    <input value={description} onChange={e => setDescription(e.target.value)} />
                    <label>Choose a zip</label>
                    <input type="file" onChange={onChange} className="generator__form" accept="application/x-zip-compressed" multiple={false} />
                    <label >Choose a preview(jpg only)</label>
                    <input type="file" onChange={onChangePreview} className="generator__form" accept="image/jpg" multiple={false} />
                    <button onClick={send} disabled={!name || !file || !preview || processing}>Send zip</button>
                </>}
                {mode === 'edit' && <>
                    <label>Model name</label>
                    <input value={name} onChange={e => setName(e.target.value)} />
                    <label>Description</label>
                    <input value={description} onChange={e => setDescription(e.target.value)} />
                    <label>Choose a preview(jpg only)</label>
                    <input type="file" onChange={onChangePreview} className="generator__form" accept="image/jpg" multiple={false} />
                    <button onClick={update} disabled={processing}>UPDATE</button>
                    <div />
                    <div />
                    <div />
                    <button onClick={() => deleteTrainedModel()} disabled={deleteInput !== 'DELETE'}>DELETE</button>
                    <label>To delete type 'delete'</label>
                    <input value={deleteInput} onChange={e => setDeleteInput(e.target.value)} />
                </>}
                {processing && <>
                    <div className="generator__processing" />
                    <div>Loading...</div>
                </>}
            </div>
            <div className="generator__right">
                {models.map(item => (
                    <div onClick={() => selectModel(item)} className={classNames('generator__model', { generator__model_selected: trainedModel === item.id })} key={item.id}>{item.name} - <span style={{ color: 'blue' }}>{item.description}</span> - <span style={{ color: 'green' }}>{item.status}</span></div>
                ))}
            </div>
        </div>
    );
}
