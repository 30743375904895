import { createEvent, createStore } from 'effector';
import * as R from 'ramda';
import { HelpSections } from 'Components/Help/helpers/helpers';

export const $isExtendedHelp = createStore<boolean>(false);
export const $helpSection = createStore<{ section: HelpSections; itemId: string | null }>({ section: HelpSections.WHAT_IS, itemId: null });

export const setIsExtendedHelp = createEvent<boolean>();
export const setHelpSection = createEvent<{ section: HelpSections; itemId: string | null; }>();

$helpSection.on(setHelpSection, /* R.pipe(R.nthArg(1), R.pick(['section', 'itemId'])) */(_, { section, itemId }) => {
  return {
    section: section,
    itemId: itemId,
  };
});

$isExtendedHelp.on(setIsExtendedHelp, R.nthArg(1));
