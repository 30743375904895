import config from "config";

export type FileData = {
    filename: string;
    originalname: string;
    note: string;
}

type UploadProps = {
    file: File;
    note: string;
}

export async function upload({ file, note }: UploadProps): Promise<FileData> {
    const url = new URL(config.backendUrl + '/api-quarters/file/presigned-link');
    url.searchParams.append('originalname', file.name);
    url.searchParams.append('note', note);

    const responseLink = await fetch(url, { credentials: 'include' });
    if (!responseLink.ok) throw new Error('Fetch error.');

    const data = await responseLink.json();
    if (data.error) throw new Error(data.result);

    const formData = new FormData();
    Object.entries(data.result.formData).forEach((entry) => formData.append(entry[0], entry[1] as string));
    formData.append('file', file);
    const response = await fetch(data.result.postURL, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) throw new Error('Выгрузка изображения завершилась неудачей.');

    return {
        filename: data.result.formData.key.split('/').pop(),
        originalname: file.name,
        note,
    };
}

export type Model = {
    id: string;
    name: string;
    description: string;
    status: string;
    training_id: string;
    trigger_word: string;
};

export async function loadModels(): Promise<Array<Model>> {
    const res = await fetch('https://quarters-dev.site/api/trained-model');

    if (!res.ok) throw new Error('Bad request.');

    const { result } = await res.json();

    return result;
}
