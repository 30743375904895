import { useCallback } from 'react';
import { useStore } from 'effector-react';
import * as Listeners from 'entities/sketch/Listeners';
import Drawing from './Drawing';
import LeftBar from './LeftBar';
import List from './List';
import { useManager } from 'entities/sketch/Manager';
import Header from 'Components/Header';
import { useHotKey } from 'hooks';
import classNames from 'classnames';
import MatchDefinitionHint from './MatchDefinitionHint';
import DownloadForm from './DownloadForm';
import { $rightSide, RightSide } from 'entities/router';
import Footer from './Footer';
import './Sketch.scss';


export default function Sketch() {
    const rightSide = useStore($rightSide);
    const manager = useManager('self');
    const matchDefinition = useManager('matchDefinition');
    const listener = useManager('listener');

    const setMatchDefinition = useCallback(() => {
        if (listener instanceof Listeners.MatchDefinition) manager.matchDefinition = null;
        else manager.listener = new Listeners.MatchDefinition(manager);
    }, [manager, listener]);

    useHotKey('i', setMatchDefinition, { notInput: true });

    return (
        <div
            className={classNames({
                sketch: true,
                'cursor-match-definition': listener instanceof Listeners.MatchDefinition,
                'cursor-match-definition-full': listener instanceof Listeners.MatchDefinition && matchDefinition && matchDefinition.full,
            })}
        >
            <MatchDefinitionHint />
            <Header type="sketch" />
            <div className="sketch__body">
                <LeftBar />
                <Drawing />
                <Footer />
                {rightSide === RightSide.LIST && <List />}
                {rightSide === RightSide.DOWNLOAD_FORM && <DownloadForm />}
            </div>
        </div >
    );
}
