import { useEffect, useState } from 'react';
import EventEmitter from 'eventemitter3';
import { Group, GroupData, ListData, Manager, Segment, SegmentData } from './Manager';

type Snapshot = {
    list: Array<ListData>;
    segments: Array<SegmentData & { mask: Uint8Array }>;
    groups: Array<GroupData>;
    selectedBackground: Manager['selectedBackground'];
};

export class History extends EventEmitter {
    private readonly manager: Manager;
    private currentState!: Snapshot;
    private prototypeCurrentState: HTMLImageElement | null = null;

    #undo: Array<Snapshot> = [];
    #redo: Array<Snapshot> = [];

    #prototypeUndo: Array<HTMLImageElement> = [];
    #prototypeRedo: Array<HTMLImageElement> = [];

    get undo() {
        return this.#undo;
    }
    private set undo(value: Array<Snapshot>) {
        this.#undo = value;
        this.emit('undo', this.undo);
    }

    get redo() {
        return this.#redo;
    }
    private set redo(value: Array<Snapshot>) {
        this.#redo = value;
        this.emit('redo', this.redo);
    }

    get prototypeUndo() {
        return this.#prototypeUndo;
    }
    private set prototypeUndo(value: Array<HTMLImageElement>) {
        this.#prototypeUndo = value;
        this.emit('prototypeUndo', this.prototypeUndo);
    }

    get prototypeRedo() {
        return this.#prototypeRedo;
    }
    private set prototypeRedo(value: Array<HTMLImageElement>) {
        this.#prototypeRedo = value;
        this.emit('prototypeRedo', this.prototypeRedo);
    }

    get self() {
        return this;
    }

    constructor(manager: Manager) {
        super();
        this.manager = manager;
    }

    public create() {
        const { list } = this.manager.getSketchData();
        const segments = this.manager.segments.map(segment => ({
            ...segment.toJSON(),
            mask: segment.mask.slice(),
        }));
        const groups = this.manager.groups.map(group => group.toJSON());
        if (this.currentState) this.undo = [...this.#undo, this.currentState];
        this.redo = [];
        this.currentState = ({ list, segments, groups, selectedBackground: this.manager.selectedBackground });
        this.prototypeCurrentState = this.manager.prototype;
    }

    public undoAction() {
        const snapshot = this.#undo.pop();
        if (!snapshot) throw new Error('Snapshot is undifined.');

        this.undo = [...this.#undo];
        this.redo = [...this.#redo, this.currentState];

        this.currentState = snapshot;
        this.apply(this.currentState);
    }

    public redoAction() {
        const snapshot = this.#redo.pop();
        if (!snapshot) throw new Error('Snapshot is undifined.');

        this.undo = [...this.#undo, this.currentState];
        this.redo = [...this.#redo];

        this.currentState = snapshot;
        this.apply(this.currentState);
    }

    public applyCurrentState() {
        this.apply(this.currentState);
    }

    private apply(snapshot: Snapshot) {
        if (this.manager.segments.length !== snapshot.segments.length || this.manager.segments.every(segment => snapshot.segments.some(s => s.id !== segment.id))) {
            this.manager.segments.filter(segment => !snapshot.segments.some(s => s.id === segment.id)).forEach(segment => this.manager.removeSegments([segment], true));
            snapshot.segments.filter(segment => !this.manager.segments.some(s => s.id === segment.id)).forEach(segmentData => this.manager.createNewSegment(segmentData.id));
        }
        if (this.manager.groups.length !== snapshot.groups.length || this.manager.groups.every(group => snapshot.groups.some(g => g.id !== group.id))) {
            const groups = this.manager.groups.filter(group => snapshot.groups.some(g => g.id === group.id));
            snapshot.groups.filter(group => !this.manager.groups.some(g => g.id === group.id)).forEach(({ segments: segmentIds, ...groupData }) => {
                const segments: Array<Segment> = [];
                segmentIds.forEach(id => {
                    const segment = this.manager.segments.find(s => s.id === id);
                    if (!segment) throw new Error(`Segment ${id} not found.`);
                    segments.push(segment);
                });
                groups.push(new Group({ ...groupData, manager: this.manager, segments: segments }));
            });
            this.manager.groups = groups;
        }
        this.manager.segments.forEach(segment => {
            const segmentData = snapshot.segments.find(s => s.id === segment.id);
            if (!segmentData) throw new Error('Segment not found.');
            segment.sync(segmentData);
        });
        this.manager.segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
        this.manager.groups.forEach(group => {
            const groupData = snapshot.groups.find(g => g.id === group.id);
            if (!groupData) throw new Error('Group not found.');
            group.sync(groupData);
        });
        this.manager.list = snapshot.list.map(element => {
            if (element.constructor === 'Segment') {
                const segment = this.manager.segments.find(segment => segment.id === element.id);
                if (!segment) throw new Error(`Segment ${element.id} not found.`);
                return segment;
            }
            if (element.constructor === 'Group') {
                const group = this.manager.groups.find(group => group.id === element.id);
                if (!group) throw new Error(`Group ${element.id} not found.`);
                return group;
            }
            throw new Error(`Element ${element.constructor} not implemented.`);
        });
        this.manager.unselectAllElements();
    }

    public prototypeCreate() {
        if (this.prototypeCurrentState) this.prototypeUndo = [...this.#prototypeUndo, this.prototypeCurrentState];
        this.prototypeRedo = [];
        this.prototypeCurrentState = this.manager.prototype;
    }

    public async prototypeUndoAction() {
        const image = this.prototypeUndo.pop();
        if (!image) throw new Error('Image is undifined.');

        if (!this.prototypeCurrentState) throw new Error('Current prototype state is undifined.');

        this.prototypeUndo = [...this.#prototypeUndo];
        this.prototypeRedo = [...this.#prototypeRedo, this.prototypeCurrentState];

        this.prototypeCurrentState = image;

        this.manager.prototype = image;

        if (this.manager.selectedBackground === 'prototype') this.manager.selectBackground('prototype');
    }

    public async prototypeRedoAction() {
        const image = this.prototypeRedo.pop();
        if (!image) throw new Error('Image is undifined.');

        if (!this.prototypeCurrentState) throw new Error('Current prototype state is undifined.');

        this.prototypeUndo = [...this.#prototypeUndo, this.prototypeCurrentState];
        this.prototypeRedo = [...this.#prototypeRedo];

        this.prototypeCurrentState = image;

        this.manager.prototype = image;

        if (this.manager.selectedBackground === 'prototype') this.manager.selectBackground('prototype');
    }

    public clearPrototypeHistory() {
        this.prototypeUndo = [];
        this.prototypeRedo = [];
        this.prototypeCurrentState = null;
    }
}

export function useHistory<T extends keyof Pick<History, 'undo' | 'redo' | 'self' | 'prototypeUndo' | 'prototypeRedo'>>(manager: Manager, key: T): History[T] {
    const [value, setValue] = useState(manager.history[key]);

    useEffect(() => {
        setValue(manager.history[key]);
        manager.history.addListener(key, setValue);
        return () => void manager.history.removeListener(key, setValue);
    }, [manager, key]);

    return value;
}
