import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { Draggable, DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { Group, Segment, useEditor, useGroup, useSegment, useManager } from 'entities/sketch/Manager';
import * as Listeners from 'entities/sketch/Listeners';
import { ReactComponent as DNDSVG } from './icons/dnd.svg';
import { ReactComponent as SegmentsSVG } from './icons/segments.svg';
import { ReactComponent as PolygonSVG } from './icons/polygon.svg';
import { ReactComponent as CheckBoxSVG } from './icons/checkbox.svg';
import { ReactComponent as CheckBoxCheckedSVG } from './icons/checkbox_checked.svg';
import { ReactComponent as CheckBoxDisabledSVG } from './icons/checkbox_disabled.svg';
import { ReactComponent as CrossSVG } from './icons/cross.svg';
import { ReactComponent as DisabledSVG } from './icons/disabled.svg';
import { ReactComponent as TextInpaintActiveSVG } from './icons/text-inpaint_active.svg';
import { ReactComponent as AttachmentInpaintSVG } from './icons/attachment-inpaint.svg';
import { ReactComponent as TextInpaintSVG } from './icons/text-inpaint.svg';
import { ReactComponent as AttachmentInpaintActiveSVG } from './icons/attachment-inpaint_active.svg';
import { ReactComponent as UngroupSVG } from './icons/ungroup.svg';
import { ListType } from '../List';
import { InpaintState, InpaintType, useInpaint } from 'entities/sketch/Inpaint';
import { setElementForAttach } from 'entities/library';
import { $userId } from 'entities/user';
import Button from 'Components/Button';
import { useHotKey } from 'hooks';
import './GroupElement.scss';


type GroupProps = {
    group: Group;
    index: number;
    listType: ListType;
    matchDefinition: undefined | ((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void);
};

enum CheckBoxState {
    REGULAR = 'REGULAR',
    CHECKED = 'CHECKED',
    DISABLED = 'DISABLED',
}

export function GroupElement({ group, index, listType, matchDefinition }: GroupProps) {
    const manager = useManager('self');
    const inpaintingQueue = useManager('inpaintingQueue');
    const selectedElements = useManager('selectedElements');
    const selectedElemets = useManager('selectedElements');
    const hoveredElements = useManager('hoveredElements');
    const editingGroup = useManager('editingGroup');
    const listener = useManager('listener');
    const editing = useEditor('editing');
    const attachment = useGroup(group, 'attachment');
    const segments = useGroup(group, 'segments');
    const inpaintType = useInpaint(group.inpaint, 'inpaintType');
    const inpaintState = useInpaint(group.inpaint, 'inpaintState');
    const [checkBoxState, setCheckBoxState] = useState<CheckBoxState>(CheckBoxState.REGULAR);
    const [segmentsIsOpened, setSegmentsIsOpened] = useState(true);

    const groupHovered = useMemo(() => hoveredElements.includes(group), [hoveredElements, group]);
    const groupSelected = useMemo(() => selectedElements.includes(group), [selectedElements, group]);
    const selected = useMemo(() => selectedElemets.includes(group), [selectedElemets, group]);

    const selectAttachmentInpaint = useCallback(() => {
        if (!attachment) setElementForAttach(group);
        else group.inpaint.inpaintType = InpaintType.ATTACHMENT;
    }, [group, attachment]);

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {
        if (group.inpaint.inpaintState === InpaintState.COMPLETED) group.inpaint.updateInpaintState();
        if (matchDefinition && (group.description || group.attachment) && (!manager.matchDefinition || !manager.matchDefinition.full)) manager.matchDefinition = { element: group, full: false, x: e.clientX, y: e.clientY };
        else manager.hoveredElements = [group];
    }, [group, manager, matchDefinition]);

    const onMouseLeave = useCallback(() => {
        if (manager.matchDefinition && !manager.matchDefinition.full && manager.matchDefinition.element === group) manager.matchDefinition = null;
        if (manager.hoveredElements[0] === group) manager.hoveredElements = [];
    }, [group, manager]);

    const inputFocus = useCallback(() => {
        if (inpaintType === InpaintType.ATTACHMENT) return;
        const input = document.getElementById(`group_${group.id}_input`);
        if (!input) throw new Error('Input not exist.');
        input.focus();
    }, [group, inpaintType]);

    const onClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (matchDefinition) return matchDefinition(e, group.id);
        if (editing) return;

        let target: unknown = e.target;
        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (target.getAttribute('data-type') === 'action') return;
            target = target.parentElement;
        }

        if (selected) manager.unselectElement(group);
        else manager.selectOneElement(group);
    }, [group, manager, selected, editing, matchDefinition]);

    useHotKey('Enter', () => manager.doneEditingGroup(), { condition: editingGroup === group });

    useEffect(() => {
        if (matchDefinition || editing || editingGroup || inpaintingQueue.includes(group.inpaint)) return setCheckBoxState(CheckBoxState.DISABLED);
        if (groupSelected) return setCheckBoxState(CheckBoxState.CHECKED);
        return setCheckBoxState(CheckBoxState.REGULAR);
    }, [matchDefinition, editing, editingGroup, groupSelected, inpaintingQueue, group]);

    const changeInpaintTypeDisabled = useMemo(() => {
        if (inpaintingQueue.includes(group.inpaint)) return true;
        if (editing) return true;
        if (editingGroup) return true;
        if (listener instanceof Listeners.MatchDefinition) return true;
        return false;
    }, [inpaintingQueue, editing, editingGroup, listener, group]);
    const DNDDisabled = useMemo(() => {
        if (editingGroup) return true;
        if (inpaintingQueue.includes(group.inpaint) && listType === ListType.REGULAR) return true;
        if (listener instanceof Listeners.MatchDefinition) return true;
        return false;
    }, [editingGroup, inpaintingQueue, listType, listener, group]);

    return (
        <>
            <Draggable draggableId={group.id} index={index}>
                {(provided, snapshot) => (
                    <div className="group__container" {...provided.draggableProps} ref={provided.innerRef}>
                        <div className={classNames('group', { group_hovered: groupHovered })} onMouseEnter={e => onMouseEnter(e)} onMouseLeave={() => onMouseLeave()} onClick={e => onClick(e)}>
                            <div className="group__dnd-cell">
                                <div {...provided.dragHandleProps} tabIndex={-1} className={classNames({ 'group__dnd-handle-container': true, disabled: DNDDisabled })}>
                                    <DNDSVG data-type="action" />
                                </div>
                            </div>
                            <div className="group__check-box-cell">
                                {checkBoxState === CheckBoxState.REGULAR && <CheckBoxSVG className="pointer" onClick={() => manager.selectElement(group)} data-type="action" />}
                                {checkBoxState === CheckBoxState.CHECKED && <CheckBoxCheckedSVG className="pointer" onClick={() => manager.unselectElement(group)} data-type="action" />}
                                {checkBoxState === CheckBoxState.DISABLED && <CheckBoxDisabledSVG />}
                            </div>
                            <div className="group__open-cell">
                                <div className="group__open-button" onClick={() => setSegmentsIsOpened(prev => !prev)} data-type="action">
                                    <SegmentsSVG />
                                    <PolygonSVG className={classNames({ rotated: !segmentsIsOpened })} />
                                </div>
                            </div>
                            <div className="group__inpaint-cell">
                                {inpaintState === InpaintState.DEFAULT && <div className="group__icon group__inpaint-icon" data-type="action" onClick={() => manager.addToInpaintingQueue(group.inpaint)} />}
                                {inpaintState === InpaintState.CHANGED && <div className="group__icon group__inpaint-changed-icon" data-type="action" onClick={() => manager.addToInpaintingQueue(group.inpaint)} />}
                                {inpaintState === InpaintState.EMPTY && <div className="group__icon group__inpaint-empty-icon" />}
                                {inpaintState === InpaintState.COMPLETED && <div className="group__icon group__inpaint-completed-icon" />}
                                {inpaintState === InpaintState.PENDING && <div className="group__icon group__inpaint-pending-icon" data-type="action" onClick={() => manager.cancelInpainting(group.inpaint)} />}
                                {inpaintState === InpaintState.IN_PROGRESS && <div className="group__icon group__inpaint-in-progress-icon" data-type="action" onClick={() => manager.cancelInpainting(group.inpaint)} />}
                                {inpaintState === InpaintState.PAUSE && <div className="group__icon group__inpaint-pause-icon" data-type="action" onClick={() => manager.cancelInpainting(group.inpaint)} />}
                            </div>
                            <div className="group__input-cell" onClick={inputFocus}>
                                <div className="group__input-container">
                                    {inpaintType === InpaintType.TEXT && <TextInput group={group} />}
                                    {inpaintType === InpaintType.ATTACHMENT && <Attachment group={group} />}
                                </div>
                                {
                                    editingGroup === group
                                    &&
                                    <div className={classNames('group__editing-buttons', { 'group__editing-buttons_attachment': inpaintType === InpaintType.ATTACHMENT })}>
                                        <Button size="small" color="dark" data={{ 'data-type': 'action' }} onClick={() => manager.doneEditingGroup()}>DONE</Button>
                                        <Button size="small" color="white" data={{ 'data-type': 'action' }} className="group__editing-cancel-button" onClick={() => manager.cancelEditingGroup()}>CANCEL</Button>
                                    </div>
                                }
                            </div>
                            <div className="group__inpaint-type-cell">
                                {inpaintType === InpaintType.TEXT &&
                                    <>
                                        <TextInpaintActiveSVG className={classNames('group__text-inpaint-icon', { disabled: changeInpaintTypeDisabled })} />
                                        <div className={classNames('group__attachment-inpaint-icon group__icon pointer', { disabled: changeInpaintTypeDisabled })} onClick={selectAttachmentInpaint} data-type="action">
                                            <AttachmentInpaintSVG className=" group__icon" />
                                        </div>
                                    </>
                                }
                                {inpaintType === InpaintType.ATTACHMENT &&
                                    <>
                                        <div className={classNames('group__text-inpaint-icon group__icon pointer', { disabled: changeInpaintTypeDisabled })} onClick={() => group.inpaint.inpaintType = InpaintType.TEXT} data-type="action">
                                            <TextInpaintSVG className=" group__icon" />
                                        </div>
                                        <AttachmentInpaintActiveSVG className={classNames('group__attachment-inpaint-icon', { disabled: changeInpaintTypeDisabled })} />
                                    </>
                                }
                            </div>
                            <div className="group__ungroup-cell">
                                <div className={classNames('group__ungroup-cell-icon group__icon', { disabled: inpaintingQueue.includes(group.inpaint) || editing || editingGroup })} onClick={() => manager.ungroup([group])} data-type="action">
                                    <UngroupSVG className="group__ungroup-icon" />
                                </div>
                            </div>
                        </div>
                        {segmentsIsOpened && editingGroup !== group && segments.map(segment => <SegmentElement key={segment.id} segment={segment} group={group} editing={false} matchDefinition={matchDefinition} />)}
                    </div>
                )}
            </Draggable >
            <div className="group__segments">
                {
                    segmentsIsOpened && editingGroup === group && segments.map((segment, i) => (
                        <Draggable draggableId={segment.id} index={index + 1 + i} key={segment.id}>
                            {(providedGroup, snapshot) => (<SegmentElement key={segment.id} segment={segment} group={group} editing={true} dragHandleProps={providedGroup.dragHandleProps} draggableProps={providedGroup.draggableProps} innerRef={providedGroup.innerRef} matchDefinition={matchDefinition} />)}
                        </Draggable>
                    ))
                }
            </div>
        </>
    );
}

type AttachmentProps = {
    group: Group;
};

function Attachment({ group }: AttachmentProps) {
    const userId = useStore($userId);
    const listener = useManager('listener');
    const attachment = useGroup(group, 'attachment');

    const src = useMemo(() => attachment?.getImageSrc(userId) || '', [attachment, userId]);

    if (!attachment) throw new Error('Attachment is not defined.');

    return (
        <div className={classNames('group__attachment', { disabled: listener instanceof Listeners.MatchDefinition })}>
            <img src={src} alt="attchament preview" className="group__attachment-image" />
            <div className="group__attachment-filename">{attachment.originalname}</div>
            <CrossSVG className="group__attachment-remove" onClick={() => group.unattach()} data-type="action" />
        </div>
    );
}

type TextInputProps = {
    group: Group;
};

function TextInput({ group }: TextInputProps) {
    const manager = useManager('self');
    const inpaintingQueue = useManager('inpaintingQueue');
    const listener = useManager('listener');
    const description = useGroup(group, 'description');
    const [prevDescription, setPrevDescription] = useState(description);

    const onBlur = useCallback(() => {
        if (prevDescription === description) return;
        else {
            manager.history.create();
            manager.saveSketch();
        }
    }, [manager, prevDescription, description]);

    const onClick = useCallback(() => {
        const input = document.getElementById(`group_${group.id}_input`);
        if (!input) throw new Error('Input is not found.');
        input.focus();
    }, [group]);

    const disabled = useMemo(() => inpaintingQueue.includes(group.inpaint), [inpaintingQueue, group]);

    return (
        <div className={classNames('group__text-area', { disabled: listener instanceof Listeners.MatchDefinition })} data-type="action" onClick={() => onClick()}>
            <TextareaAutosize
                maxRows={4}
                id={`group_${group.id}_input`}
                value={description}
                onChange={e => group.description = e.currentTarget.value}
                className={classNames('group__description', { disabled })}
                placeholder="Type or attach to define"
                onFocus={() => setPrevDescription(description)}
                onBlur={onBlur}
                disabled={disabled}
            />
        </div>
    );
}

type SegmentElementProps = {
    segment: Segment;
    group: Group;
    editing: boolean;
    dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
    draggableProps?: DraggableProvidedDraggableProps;
    innerRef?: (element: HTMLElement | null) => void
    matchDefinition: undefined | ((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void);
};

function SegmentElement({ segment, group, editing, dragHandleProps, draggableProps, innerRef, matchDefinition }: SegmentElementProps) {
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const hoveredElements = useManager('hoveredElements');
    const selectedElements = useManager('selectedElements');
    const color = useSegment(segment, 'color');
    const attachment = useSegment(segment, 'attachment');
    const [checkBoxState, setCheckBoxState] = useState<CheckBoxState>(CheckBoxState.REGULAR);

    const segmentHovered = useMemo(() => hoveredElements.includes(segment), [hoveredElements, segment]);
    const segmentSelected = useMemo(() => selectedElements.includes(segment), [selectedElements, segment]);

    const clickSegment = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (matchDefinition) return matchDefinition(e, segment.id);
        if (!editing) return;
        if (!e.ctrlKey && !e.metaKey) return;
        if (group.segments.includes(segment)) manager.removeSegmentFromGroup(segment, group, 0);
        else throw new Error('Group has this segment.');
    }, [editing, group, manager, segment, matchDefinition]);

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {
        if (matchDefinition && (segment.description || segment.attachment) && (!manager.matchDefinition || !manager.matchDefinition.full)) manager.matchDefinition = { element: segment, full: false, x: e.clientX, y: e.clientY };
        else manager.hoveredElements = [segment];
    }, [segment, manager, matchDefinition]);

    const onMouseLeave = useCallback(() => {
        if (manager.matchDefinition && !manager.matchDefinition.full && manager.matchDefinition.element === segment) manager.matchDefinition = null;
        if (manager.hoveredElements[0] === segment) manager.hoveredElements = [];
    }, [segment, manager]);

    useEffect(() => {
        if (matchDefinition || editing || editingGroup) return setCheckBoxState(CheckBoxState.DISABLED);
        if (segmentSelected) return setCheckBoxState(CheckBoxState.CHECKED);
        return setCheckBoxState(CheckBoxState.REGULAR);
    }, [matchDefinition, editing, editingGroup, segmentSelected, segment]);

    return (
        <div className="group__segment" {...draggableProps} ref={innerRef} onClick={e => clickSegment(e)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="group__segment-dnd-cell">
                <div className={classNames({ 'group__segment-dnd-handle-container': true, disabled: !editing })} {...dragHandleProps}>
                    <DNDSVG data-type="action" />
                </div>
            </div>
            <div className="group__segment-check-box-cell">
                {checkBoxState === CheckBoxState.REGULAR && <CheckBoxSVG className="pointer" onClick={() => manager.selectElement(segment)} data-type="action" />}
                {checkBoxState === CheckBoxState.CHECKED && <CheckBoxCheckedSVG className="pointer" onClick={() => manager.unselectElement(segment)} data-type="action" />}
                {checkBoxState === CheckBoxState.DISABLED && <CheckBoxDisabledSVG />}
            </div>
            <div className="group__segment-color-cell">
                <div className="group__segment-color" data-type="action" style={{ backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})` }} onClick={() => manager.changeToRandomColor(segment)} />
                <div className="group__segment-name">{segment.name.toString().padStart(3, '0')}</div>
            </div>
            <div className="group__segment-input-cell">
                <div className="group__segment-disabled">
                    <div className="group__segment-disabled-item">
                        <DisabledSVG />
                        <div className="group__segment-disabled-item-text">Disabled</div>
                    </div>
                </div>
                {attachment
                    ?
                    <SegmentAttachment segment={segment} />
                    :
                    <SegmentText segment={segment} />
                }
            </div>
            <div className="group__segment-empty-cell" />
            <div className="group__segment-remove-cell">
                <div className={classNames({ 'group__segment-remove-icon': true, pointer: true, hidden: !segmentHovered })} data-type="action" onClick={() => manager.removeSegments([segment])} />
            </div>
        </div >
    );
}

type SegmentAttachmentProps = {
    segment: Segment;
};

function SegmentAttachment({ segment }: SegmentAttachmentProps) {
    const userId = useStore($userId);
    const attachment = useSegment(segment, 'attachment');

    const src = useMemo(() => attachment?.getImageSrc(userId) || '', [attachment, userId]);

    if (!attachment) throw new Error('Attachment is not defined.');

    return (
        <div className="group__segment-attachment">
            <img src={src} alt="attchament preview" className="group__segment-attachment-image" />
            <div className="group__segment-attachment-filename">{attachment.originalname}</div>
        </div>
    );
}

type SegmentTextProps = {
    segment: Segment;
};

function SegmentText({ segment }: SegmentTextProps) {
    const description = useSegment(segment, 'description');

    return (
        <div className="group__segment-text">{description}</div>
    );
}
