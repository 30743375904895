import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { $notificationType, NotificationType } from 'entities/notification';
import JoinMaskNotification from './JoinMaskNotification';


export default function Notification() {
    const notificationType = useStore($notificationType);

    const component = useMemo(() => {
        switch (notificationType) {
            case null:
                return null;
            case NotificationType.JOIN_MASKS:
                return (<JoinMaskNotification />);
            default:
                throw new Error('Unknown notification type');
        }
    }, [notificationType]);

    return component;
}
