import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import * as Listeners from 'entities/sketch/Listeners';
import { ReactComponent as CloseSVG } from './icons/close.svg';
import { ReactComponent as RedrawSVG } from './icons/redraw.svg';
import { ReactComponent as PauseSVG } from './icons/pause.svg';
import { ReactComponent as UndoSVG } from './icons/undo.svg';
import { ReactComponent as RedoSVG } from './icons/redo.svg';
import { ReactComponent as TerminateSVG } from './icons/terminate.svg';
import { ReactComponent as ResumeSVG } from './icons/resume.svg';
import { ReactComponent as CheckSVG } from './icons/check.svg';
import { InpaintingQueueState, ManagerInitStep, useInitiator, useManager } from 'entities/sketch/Manager';
import useCursor from './hooks/useCursos';
import { $modal, ModalType } from 'entities/modal';
import { $elementForAttach } from 'entities/library';
import { setNotification } from 'entities/notification';
import { useUploader } from 'entities/sketch/Uploader';
import Button from 'Components/Button';
import { $shadow, Highlight } from 'entities/everything';
import { useHistory } from 'entities/sketch/History';
import { InpaintType } from 'entities/sketch/Inpaint';
import './Drawing.scss';


export default function Drawing() {
    const ref = useRef<HTMLDivElement | null>(null);
    const [size, setSize] = useState<{ width: 'auto' | number, height: number }>({ width: 'auto', height: 300 });
    const [grabbing, setGrabbing] = useState(false);
    const [ctrl, setCtrl] = useState(false);
    const [shift, setShift] = useState(false);
    const modal = useStore($modal);
    const elementForAttach = useStore($elementForAttach);
    const shadow = useStore($shadow);
    const manager = useManager('self');
    const selectedBackground = useManager('selectedBackground');
    const deltaFrame = useManager('deltaFrame');
    const listener = useManager('listener');
    const initStep = useInitiator('initStep');
    const inputLoaded = useInitiator('inputLoaded');

    useCursor();

    useEffect(() => {
        const canvas = document.getElementById('drawing__container');
        if (!canvas) return;
        let prev: Listeners.Listener | undefined;

        function onMouseDown(e: MouseEvent) {
            if (e.button !== 1) return;
            prev = manager.listener;
            manager.listener = new Listeners.Zoom(manager);
            listener.onMouseDown(e);
        }

        function onMouseUp() {
            if (!prev) return;
            manager.listener = prev;
            prev = undefined;
            listener.onMouseLeave();
        }

        canvas.addEventListener('mousedown', onMouseDown);
        canvas.addEventListener('mouseup', onMouseUp);
        canvas.addEventListener('mouseleave', onMouseUp);
        return () => {
            canvas.removeEventListener('mousedown', onMouseDown);
            canvas.removeEventListener('mouseup', onMouseUp);
            canvas.removeEventListener('mouseleave', onMouseUp);
        };
    }, [manager, listener]);

    const { startDrag, endDrag } = useMemo(() => {
        let prev: Listeners.Listener | undefined;

        function startDrag(e?: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) {
            if (manager.listener instanceof Listeners.Zoom) return;
            prev = manager.listener;
            const next = new Listeners.Zoom(manager);
            if (e) next.onMouseDown(e);
            manager.listener = next;
        }

        function endDrag() {
            if (!prev) return;
            manager.listener = prev;
            prev = undefined;
        }

        return { startDrag, endDrag };
    }, [manager]);

    useEffect(() => {
        function start(e: KeyboardEvent) {
            if (e.code === 'Space' && document.activeElement instanceof HTMLBodyElement) startDrag();
        }

        function end(e: KeyboardEvent) {
            if (e.code === 'Space') endDrag();
        }

        document.addEventListener('keydown', start);
        document.addEventListener('keyup', end);

        return () => {
            document.removeEventListener('keydown', start);
            document.removeEventListener('keyup', end);
        };
    }, [startDrag, endDrag]);

    useEffect(() => {
        if (modal.type === ModalType.NONE && !elementForAttach) {
            function fn(e: KeyboardEvent) {
                if (e.code !== 'Escape') return;

                manager.unselectAllElements();
            }

            document.addEventListener('keydown', fn);
            return () => document.removeEventListener('keydown', fn);
        }
    }, [modal, manager, elementForAttach]);

    useEffect(() => {
        const handleKeydown = (e: KeyboardEvent) => {
            if (listener instanceof Listeners.Select && e.key === 'Tab') {
                e.preventDefault();
                listener.handleTab();
            }
        }

        document.addEventListener('keydown', handleKeydown);

        return () => document.removeEventListener('keydown', handleKeydown);
    }, [listener]);

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        if (!(canvas instanceof HTMLCanvasElement)) throw new Error('canvas not canvas =)');
        if (!manager) throw new Error('Segments not exist');
        manager.connectCanvas(canvas);
    }, [manager]);

    useEffect(() => {
        const container = ref.current;
        if (!container) throw new Error('Container not exist.');

        const resizeObserver = new ResizeObserver(e => setSize({ width: e[0].contentRect.width, height: e[0].contentRect.height }));
        resizeObserver.observe(container);

        return () => resizeObserver.unobserve(container);
    }, []);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) return;

        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "[") manager.changeCursorRadius(-1);

            if (event.key === "]") manager.changeCursorRadius(1);
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) manager.setCursor(null);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.Zoom)) setGrabbing(false);
        else listener.setGrabbing = setGrabbing;
    }, [listener]);

    useEffect(() => {
        function down(event: KeyboardEvent) {
            if (event.ctrlKey || event.metaKey) setCtrl(true);
            if (event.shiftKey) setShift(true);
        }
        function up(event: KeyboardEvent) {
            if (!event.ctrlKey && !event.metaKey) setCtrl(false);
            if (!event.shiftKey) setShift(false);
        }

        document.addEventListener('keydown', down);
        document.addEventListener('keyup', up);

        return () => {
            document.removeEventListener('keydown', down);
            document.removeEventListener('keyup', up);
        }
    }, []);

    const onMouseDown = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) => {
        if (e.button !== 1) return listener.onMouseDown(e);

        startDrag(e);
    }, [listener, startDrag]);

    const onMouseUp = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onMouseUp(e);

        endDrag();
    }, [listener, endDrag]);

    const onMouseClick = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onClick(e);
    }, [listener]);

    const selectInputBackground = useCallback(() => {
        manager.listener = new Listeners.Zoom(manager);
        manager.selectBackground('input');
    }, [manager,]);

    return (
        <div id="drawing" className="drawing" ref={ref} >
            {!inputLoaded && selectedBackground === 'input' && initStep !== ManagerInitStep.LOADING && <UploadFrame />}
            {initStep === ManagerInitStep.PROTOTYPING && <PrototypeLoader />}
            <canvas
                id="canvas"
                className={classNames({
                    highlighted: shadow === Highlight.NOTIFY,
                    drawing__canvas: true,
                    'cursor-frame-to-add': listener instanceof Listeners.BoxListener,
                    'cursor-selection': listener instanceof Listeners.Select || (deltaFrame !== null && deltaFrame === 0),
                    'cursor-selection-add': listener instanceof Listeners.Select && ctrl && !deltaFrame,
                    'cursor-selection-remove': listener instanceof Listeners.Select && shift && !deltaFrame,
                    'cursor-cross-selection': deltaFrame !== null && deltaFrame < 0,
                    'cursor-cross-selection-add': deltaFrame !== null && deltaFrame < 0 && ctrl,
                    'cursor-cross-selection-remove': deltaFrame !== null && deltaFrame < 0 && shift,
                    'cursor-window-selection': deltaFrame !== null && deltaFrame > 0,
                    'cursor-window-selection-add': deltaFrame !== null && deltaFrame > 0 && ctrl,
                    'cursor-window-selection-remove': deltaFrame !== null && deltaFrame > 0 && shift,
                    'cursor-add': listener instanceof Listeners.PositivePoint,
                    'cursor-remove': listener instanceof Listeners.NegativePoint,
                    'cursor-brush-tool': listener instanceof Listeners.BrushToolListener,
                    'cursor-grab': listener instanceof Listeners.Zoom,
                    'cursor-grabbing': grabbing,
                })}
                onWheel={e => manager.scale(e)}
                onMouseDown={e => onMouseDown(e)}
                onMouseMove={e => listener.onMouseMove(e)}
                onMouseUp={e => onMouseUp(e)}
                onClick={e => onMouseClick(e)}
                onDoubleClick={e => listener.onDoubleClick(e)}
                onMouseLeave={() => listener.onMouseLeave()}
                {...size}
            />
            <div className="drawing__footer">
                <div className={classNames('drawing__footer-bar', { 'drawing__footer-bar_no-filter': shadow === 'backward_forward' })}>
                    <div className={classNames({ 'drawing__footer-background-selector': true, 'drawing__footer-background-selector_disabled': initStep < ManagerInitStep.PROTOTYPE_LOADED || initStep === ManagerInitStep.RECOGNIZING })}>
                        <div className={classNames({ 'drawing__footer-background-option': true, 'drawing__footer-background-option_selected': selectedBackground === 'input' })} onClick={() => selectInputBackground()}>
                            {selectedBackground === 'input' && <CheckSVG />}
                            <div className="drawing__footer-background-option-text">Input</div>
                        </div>
                        <div className={classNames({ 'drawing__footer-background-option': true, 'drawing__footer-background-option_selected': selectedBackground === 'prototype' })} onClick={() => manager.selectBackground('prototype')}>
                            {selectedBackground === 'prototype' && <CheckSVG />}
                            <div className="drawing__footer-background-option-text">Prototype</div>
                        </div>
                        {initStep >= ManagerInitStep.RECOGNIZING && <div className={classNames({ 'drawing__footer-background-option': true, 'drawing__footer-background-option_selected': selectedBackground === 'masks' })} onClick={() => manager.selectBackground('masks')}>
                            {selectedBackground === 'masks' && <CheckSVG />}
                            <div className="drawing__footer-background-option-text">Masks</div>
                        </div>}
                    </div >
                    <ZoomPanel />
                    {initStep < ManagerInitStep.RECOGNIZING ? <FooterReplaceButton /> : <div className="drawing__footer-gap" />}
                    <RedrawPanel />
                </div >
            </div>
        </div >
    );
}

function FooterReplaceButton() {
    const { initiator } = useManager('self');
    const fileName = useInitiator('fileName');
    const initStep = useInitiator('initStep');
    const inputLoaded = useInitiator('inputLoaded');

    if (!inputLoaded) return (<div className="drawing__footer-gap" />);

    return (
        <div className="drawing__footer-replace-button-container">
            <div className="drawing__footer-replace-button-file-name">{fileName.split('.').slice(0, -1).join('.') + '.'}</div>
            <div className="drawing__footer-replace-button-file-ext">{fileName.split('.').at(-1)}</div>
            <CloseSVG className={classNames({ 'drawing__footer-replace-button-file-close-button': true, disabled: initStep === ManagerInitStep.PROTOTYPING })} onClick={() => initiator.clearSource()} />
        </div>
    );
}

function PrototypeLoader() {
    return (
        <div className="drawing__prototype-loader">
            <div className="drawing__prototype-loader-spinner" />
        </div>
    );
}

function UploadFrame() {
    const manager = useManager('self');
    const checked = useUploader('checked');
    const initStep = useInitiator('initStep');
    const [isDragging, setIsDragging] = useState(false);

    const checkEndSetFile = useCallback((file: File | undefined) => {
        if (!file) throw new Error('File not exist.');
        if (initStep === ManagerInitStep.PROTOTYPING) return;

        if (!file) throw new Error('File not exist.');
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) return;
        setNotification(null);

        manager.initiator.applySource(file);
    }, [manager, initStep]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return console.error('Files not exist.');
        const file = files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleUpload = useCallback(() => {
        const input = document.getElementById('input');
        if (!input) throw new Error('Input not found.');
        input.click();
    }, []);

    const useSample = useCallback(async () => {
        const sampleDiv = document.getElementById('upload__sample');
        if (!sampleDiv) throw new Error('Sample not found.');
        const backgroundImage = window.getComputedStyle(sampleDiv).backgroundImage;
        const imageUrl = backgroundImage.replace(/url\(["']?(.*?)["']?\)/, '$1');

        const file = await fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => new File([blob], "sample.jpg", { type: blob.type }));
        manager.initiator.applySource(file);
    }, [manager]);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        setIsDragging(false);
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const dragLeave = useCallback(() => setIsDragging(false), []);

    return (
        <div
            className={classNames({ 'upload__frame-container': true, 'upload__frame-container_dragging': isDragging })}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onDragLeave={dragLeave}
        >
            <input id="input" onChange={handleChange} accept='image/jpeg, image/jpg, image/png' multiple={false} type="file" hidden />
            <div className="upload__frame">
                <div className="upload__browse" onClick={handleUpload}>
                    <div className="upload__browse-title">
                        <div>DRAG & DROP</div>
                        <div>OR</div>
                        <div>BROWSE DESIGN IMAGE TO RENDER</div>
                    </div>
                    <div className="upload__browse-button">BROWSE</div>
                    <div className="upload__browse-note">
                        <div>Only files with the following extensions</div>
                        <div>are allowed: png, jpg, jpeg.</div>
                        <div>36Mb limit, 1 file only</div>
                    </div>
                </div>
                <div className="upload__sample" onClick={useSample}>
                    <div className="upload__sample-background" id="upload__sample">
                        <div className="upload__sample-background-shadow">
                            <div className="upload__sample-title">
                                <div>OR</div>
                                <div>USE OUR PRE-MADE SAMPLE</div>
                            </div>
                            <Button size="medium" color="dark" className="upload__sample-button">USE SAMPLE</Button>
                        </div>
                    </div>
                </div>
                {checked && <div className="upload__frame-checked">Please upload an image or use a sample</div>}
            </div>
        </div>
    );
}

function RedrawPanel() {
    const manager = useManager('self');
    const selectedElements = useManager('selectedElements');
    const inpaintingQueue = useManager('inpaintingQueue');
    const inpaintingQueueState = useManager('inpaintingQueueState');
    const list = useManager('list');
    const initStep = useInitiator('initStep');
    const undo = useHistory(manager, 'prototypeUndo');
    const redo = useHistory(manager, 'prototypeRedo');
    const shadow = useStore($shadow);
    const [selectedElementsDefined, setselectedElementsDefined] = useState(false);

    const inpaint = useCallback(() => list.filter(element => selectedElements.includes(element)).forEach(element => manager.addToInpaintingQueue(element.inpaint)), [manager, selectedElements, list]);
    const stopInpainting = useCallback(() => inpaintingQueue.slice().reverse().forEach(inpaint => manager.cancelInpainting(inpaint)), [manager, inpaintingQueue]);

    const redrawDisabled = useMemo(() => {
        if (selectedElements.length === 0) return true;
        if (selectedElements.some(element => inpaintingQueue.some(inpaint => inpaint.element === element))) return true;
        if (!selectedElementsDefined) return true;

        return false;
    }, [selectedElements, inpaintingQueue, selectedElementsDefined]);

    const pauseDisabled = useMemo(() => {
        if (inpaintingQueue.length === 0) return true;

        return false;
    }, [inpaintingQueue]);

    const stopDisabled = useMemo(() => {
        if (inpaintingQueue.length === 0) return true;

        return false;
    }, [inpaintingQueue]);

    const undoDisabled = useMemo(() => {
        if (undo.length === 0) return true;
        if (inpaintingQueue.length) return true;
        if (initStep === ManagerInitStep.PROTOTYPING) return true;

        return false;
    }, [undo, initStep, inpaintingQueue]);

    const redoDisabled = useMemo(() => {
        if (redo.length === 0) return true;
        if (inpaintingQueue.length) return true;
        if (initStep === ManagerInitStep.PROTOTYPING) return true;

        return false;
    }, [redo, initStep, inpaintingQueue]);

    useEffect(() => {
        const fn = () => {
            const definitions: Array<Boolean> = [];
            selectedElements.forEach(element => {
                if (element.inpaint.inpaintType === InpaintType.ATTACHMENT) definitions.push(true);
                if (element.inpaint.inpaintType === InpaintType.TEXT) {
                    if (element.description) definitions.push(true);
                    else definitions.push(false);
                }
            });
            setselectedElementsDefined(!definitions.includes(false));
        }
        fn();
        selectedElements.forEach(element => {
            element.addListener('description', fn);
            element.inpaint.addListener('inpaintType', fn);
        });

        return () => selectedElements.forEach(element => {
            element.removeListener('description', fn);
            element.inpaint.removeListener('inpaintType', fn);
        });
    }, [selectedElements]);

    return (
        <div className="drawing__redraw-buttons" id="backward_forward">
            {initStep > ManagerInitStep.RECOGNIZING && <>
                <Button size="medium" color="dark" disabled={redrawDisabled} onClick={() => inpaint()} className={classNames('drawing__inpaint-button', { highlighted: shadow === Highlight.NOTIFY })}>
                    <RedrawSVG />
                    <div className="drawing__inpaint-button-text">REDRAW</div>
                </Button>
                {inpaintingQueueState === InpaintingQueueState.DEFAULT && <Button size="medium" color="dark" disabled={pauseDisabled} className="drawing__inpaint-small-button" onClick={() => manager.pauseInpaintingQueue()}>
                    <PauseSVG />
                </Button>}
                {inpaintingQueueState === InpaintingQueueState.PAUSED && <Button size="medium" color="dark" className="drawing__inpaint-small-button drawing__inpaint-resume-button" onClick={() => manager.resumeInpaintingQueue()}>
                    <ResumeSVG />
                </Button>}
                <Button size="medium" color="dark" disabled={stopDisabled} className="drawing__inpaint-small-button" onClick={() => stopInpainting()}>
                    <TerminateSVG />
                </Button>
            </>}
            <Button size="medium" color="dark" disabled={undoDisabled} className="drawing__inpaint-small-button" onClick={() => manager.history.prototypeUndoAction()}>
                <UndoSVG />
            </Button>
            <Button size="medium" color="dark" disabled={redoDisabled} className="drawing__inpaint-small-button" onClick={() => manager.history.prototypeRedoAction()}>
                <RedoSVG />
            </Button>
        </div>
    );
}

enum ZoomState {
    PLUS = 'PLUS',
    MINUS = 'MINUS',
    FIT = 'FIT',
}

function ZoomPanel() {
    const manager = useManager('self');
    const [state, setState] = useState<ZoomState>(ZoomState.PLUS);
    const [toolsOpened, setToolsOpened] = useState(false);
    const inputLoaded = useInitiator('inputLoaded');
    const initStep = useInitiator('initStep');
    const selectedBackground = useManager('selectedBackground');

    const selectTool = useCallback((state: ZoomState) => {
        setState(state);
        setToolsOpened(false);
    }, []);

    const onClick = useCallback(() => {
        switch (state) {
            case ZoomState.PLUS:
                manager.scaleFromButton(-1);
                break;
            case ZoomState.MINUS:
                manager.scaleFromButton(1);
                break;
            case ZoomState.FIT:
                manager.fit();
                break;
            default:
                throw new Error('Unknown zoom state');
        }
    }, [manager, state]);

    const disabled = useMemo(() => !inputLoaded && selectedBackground === 'input' && initStep !== ManagerInitStep.LOADING, [inputLoaded, selectedBackground, initStep]);

    return (
        <div className={classNames('zoom-panel', { disabled })}>
            <div className={classNames('zoom-panel__button', { ['zoom-panel__button_' + state.toLowerCase()]: true })} onClick={() => onClick()} />
            <div className={classNames('zoom-panel__arrow', { 'zoom-panel__arrow_opened': toolsOpened })} onClick={() => setToolsOpened(prev => !prev)} />
            <div className={classNames('zoom-panel__tools', { 'zoom-panel__tools_opened': toolsOpened })}>
                <div className="zoom-panel__tool" onClick={() => selectTool(ZoomState.PLUS)}>
                    <div className="zoom-panel__tool_plus-icon" />
                    <div className="zoom-panel__tool-text">Zoom in</div>
                </div>
                <div className="zoom-panel__tool" onClick={() => selectTool(ZoomState.MINUS)}>
                    <div className="zoom-panel__tool_minus-icon" />
                    <div className="zoom-panel__tool-text">Zoom out</div>
                </div>
                <div className="zoom-panel__tool" onClick={() => selectTool(ZoomState.FIT)}>
                    <div className="zoom-panel__tool_fit-icon" />
                    <div className="zoom-panel__tool-text">Fit</div>
                </div>
            </div>
        </div>
    );
}