import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowSVG } from './icons/arrow.svg';
import './ColorForm.scss';

const colors = [
    '#FFFFFF',
    '#D3D3D3',
    '#BEBEBE',
    '#909090',
    '#464646',
    '#000000',
    '#F5F5DC',
    '#E3CBA5',
    '#D2691E',
    '#8B0000',
    '#CC7722',
    '#D7A86E',
    '#B89F7A',
    '#A67C4D',
    '#8E6037',
    '#5D3A1A',
    '#D6D6D6',
    '#B0B0B0',
    '#E1C16E',
    '#8C7853',
    '#864B30',
    '#FF0000',
    '#FFA500',
    '#FFFF00',
    '#FFD700',
    '#FFC0CB',
    '#800080',
    '#0000FF',
    '#00FFFF',
    '#008080',
    '#008000',
    '#000080',
    '#00FF00',
    '#9C3B27',
];


type ColorFormProps = {
    placeholder: string;
    state: string;
    setState: (value: string) => void;
    checked: boolean;
};

export default function ColorForm({ placeholder, state, setState, checked }: ColorFormProps) {
    const element = useRef<HTMLDivElement>(null);
    const [opened, setOpened] = useState(false);
    const [errored, setErrored] = useState(false);

    const select = useCallback((option: string) => setState(option), [setState]);

    useEffect(() => void (checked && setErrored(!state)), [checked, state]);

    const onClick = useCallback(() => setOpened(prev => {
        if (!prev) element.current?.focus();
        else element.current?.blur();
        return !prev;
    }), []);

    return (
        <div ref={element} tabIndex={0} className="color-form" onBlur={() => setOpened(false)}>
            <div className={classNames({ 'color-form__input': true, 'color-form__input_errored': errored })} onClick={() => onClick()}>
                {!state && <div className="color-form__placeholder">{placeholder}</div>}
                {state && <div className="color-form__value" style={{ backgroundColor: state }} />}
                <div className="color-form__arrow-container">
                    <ArrowSVG className={classNames({ pointer: true, 'color-form__arrow_rotated': opened })} />
                </div>
                <div className="color-form__options">
                    {colors.map(option => (
                        <div className={classNames('color-form__option', { 'color-form__option_bordered': option === '#FFFFFF' })} onClick={() => select(option)} key={option} style={{ backgroundColor: option }} >
                            {option === state && <div className="color-form__checkmark" />}
                        </div>
                    ))}
                </div>
            </div>
            <div className={classNames({ 'color-form__error': true, 'color-form__error_visible': errored })}>Please select an option for this field</div>
        </div>
    );
}