import { useEffect, useState, useCallback, useMemo } from 'react';
import { useStore } from 'effector-react';
import Header from 'Components/Header';
import SketchList from './SketchList';
import RenderElement from './RenderElement';
import useClickOutside from 'services/useClickOutside';
import { $sketches, loadSketchesFx } from 'entities/sketches';
import { loadRenders, Render, Sketch } from 'services/sketch';
import config from 'config';
import { useManager } from 'entities/sketch/Manager';
import { RightSide, RoutePath, goTo, setRightSide } from 'entities/router';
import { setHelpSection, setIsExtendedHelp } from 'entities/help';
import { HelpSections } from 'Components/Help/helpers/helpers';
import './Home.scss';


export default function Home() {
    const manager = useManager('self');
    const sketches = useStore($sketches);
    const [renders, setRenders] = useState<Array<Render>>([]);
    const [selectedRenders, setSelectedRenders] = useState<string[]>([]);
    const [selectedSketch, setSelectedSketch] = useState<Sketch | null>();

    const handleCheckboxClick = useCallback((id: string) => setSelectedRenders(selectedRenders.includes(id) ? selectedRenders.filter(renderId => renderId !== id) : [...selectedRenders, id]), [selectedRenders]);

    const openPrototype = useCallback((sketch: Sketch) => {
        setRightSide(RightSide.LIST);
        goTo(RoutePath.SKETCH);
        manager.initiator.initExistedSketch(sketch.id);
    }, [manager]);

    function getFormatedDate(date: string) {
        const dateObject = new Date(date);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const createNewPrototype = useCallback(() => {
        setRightSide(RightSide.DOWNLOAD_FORM);
        goTo(RoutePath.SKETCH);
        manager.initiator.initBlank();
    }, [manager]);

    const openHelp = useCallback(() => {
        setHelpSection({ section: HelpSections.WHAT_IS, itemId: null });
        setIsExtendedHelp(true);
    }, []);

    const prototypes = useMemo(() => {
        const a = [...sketches].sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime());
        const b = a.map(sketch => (<SketchList sketch={sketch} key={sketch.id} isSelected={selectedSketch === sketch} setSelectedSketch={setSelectedSketch} openPrototype={openPrototype} />));
        return b;
    }, [sketches, selectedSketch, openPrototype]);

    useEffect(() => void loadSketchesFx(), []);
    useEffect(() => {
        if (selectedSketch) loadRenders(selectedSketch.id).then(setRenders);
    }, [selectedSketch]);

    useEffect(() => loadSketchesFx.doneData.watch(sketches => setSelectedSketch([...sketches].sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime())[0])).unsubscribe, []);

    return (
        <div className="home__wrapper">
            <Header />
            <div className="home__assistant">
                <div className="home__assistant-icon" />
                <div className="home__assistant-text">
                    <div>Hi, I’m Norma AI. You're using Prototype, a teaser release of io-buro's AI rendering feature.</div>
                    <br />
                    <div className="home__assistant-text-grey">Here’s how it works:</div>
                    <div>You provide a concept design image, and I create a realistic interpretation.</div>
                    <div>Together, we refine it until it aligns with your vision. Let’s begin!</div>
                </div>
                <div className="home__assistant-read-more" onClick={openHelp}>READ MORE</div>
            </div>
            <div className="home__elements_wrapper">
                <div className="home__prototypes_list-wrapper">
                    <div className="home__prototypes_list-header">
                        <p>PROTOTYPES</p>
                        <div className="home__create-new" onClick={createNewPrototype}>
                            <div className="home__create-new-icon" />
                            <div className="home__create-new-text">CREATE NEW PROTOTYPE</div>
                        </div>
                    </div>
                    <div className="home__prototypes-list">
                        {prototypes.length === 0 && <div className="home__prototypes-list-empty">NOTHING HERE YET. CREATE YOUR FIRST PROTOTYPE.</div>}
                        {prototypes}
                    </div>
                </div>
                {selectedSketch && <div className="home_prototypes_preview-wrapper">
                    <div className="home_prototypes_preview-header">
                        <p>{selectedSketch.name}</p>
                    </div>
                    <div className="home_prototypes_preview">
                        <div className="prototype_preview">
                            <div className="prototype_preview-image" onDoubleClick={() => openPrototype(selectedSketch)}>
                                <img src={config.backendUrl + '/api/prototype/' + selectedSketch.id + '.png'} className='prototype_preview-image-segments' alt={selectedSketch.name} />
                            </div>
                            <div className="prototype_preview-info">
                                <div className="prototype_preview-info-date">
                                    <div className="preview-info-date-item">
                                        <p className="preview-info-date-title">CREATED</p>
                                        <p>{getFormatedDate(selectedSketch.date)}</p>
                                    </div>
                                    <div className="preview-info-date-item">
                                        <p className="preview-info-date-title">LAST MODIFIED</p>
                                        <p>{getFormatedDate(selectedSketch.lastModified)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="renders_wrapper">
                            <div className="renders_header">
                                <p>IMAGES</p>
                                {renders.length !== 0 && <RendersDropDown selectedRenders={selectedRenders} renders={renders} />}
                            </div>
                            <div className="renders-box">
                                {renders.length === 0
                                    ?
                                    <p className="no_renders">NOTHING HERE YET. FIND YOUR EXPORTED IMAGES HERE.</p>
                                    :
                                    renders.map((render, index) => (<RenderElement key={index} isSelected={selectedRenders.includes(render.id)} handleCheckboxClick={handleCheckboxClick} render={render} />))
                                }
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    );
}

function RendersDropDown({ selectedRenders, renders }: { selectedRenders: Array<string>; renders: Array<Render>; }) {
    const manager = useManager('self');
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const dropdownRef = useClickOutside(() => setIsMenuOpen(false));

    const loadSelected = useCallback(() => selectedRenders.forEach(id => manager.loadRender(id)), [selectedRenders, manager]);

    const loadAll = useCallback(() => renders.forEach(render => manager.loadRender(render.id)), [renders, manager]);

    return (
        <div className="download_dropdown-wrapper" ref={dropdownRef} onClick={() => setIsMenuOpen((prev) => !prev)}>
            <div className="download_icon" />
            <p>DOWNLOAD</p>
            <div className={`dropdow_arrow ${isMenuOpen ? 'active' : ''}`} />
            {isMenuOpen && <div className="download-menu">
                <p className={selectedRenders.length ? '' : 'disabled_menu-item'} onClick={() => loadSelected()}>SELECTED</p>
                <p onClick={() => loadAll()}>ALL</p>
            </div>}
        </div>
    );
}
