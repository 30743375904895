import { useCallback, useEffect, useMemo, useState } from 'react';
import { Group, ManagerInitStep, Segment, useEditor, useInitiator, useManager } from 'entities/sketch/Manager';
import { DownloadFormTemplate, useUploader } from 'entities/sketch/Uploader';
import useStatusBar from '../Drawing/hooks/useStatusBar';
import { ReactComponent as SpinSVG } from './icons/spin.svg';
import { ReactComponent as CircleSVG } from './icons/circle.svg';
import { ReactComponent as SpinerSVG } from './icons/spiner.svg';
import './Footer.scss';


export default function Footer() {
    const initStep = useInitiator('initStep');

    const element = useMemo(() => {
        if (initStep <= ManagerInitStep.INPUT_LOADED) return <InputingFooterState />
        if (initStep === ManagerInitStep.PROTOTYPING) return <PrototypingFooterState />
        if (initStep === ManagerInitStep.PROTOTYPE_LOADED) return <PrototypedFooterState />
        if (initStep === ManagerInitStep.RECOGNIZING) return <RecognizingFooterState />
        if (initStep === ManagerInitStep.READY) return <FooterState />
        return null;
    }, [initStep]);
    return element;
}

function InputingFooterState() {
    const initStep = useInitiator('initStep');
    const state = useUploader('state');

    const formFilled = useMemo(() => Object.values(state).every(value => value), [state]);

    return (
        <div className="footer__state">
            {initStep === ManagerInitStep.CREATED && <span>To begin, load a design image and configure the settings.</span>}
            {initStep === ManagerInitStep.INPUT_LOADED && (formFilled
                ?
                <span>All set—let’s start prototyping.</span>
                :
                <span>To begin, load a design image and configure the settings.</span>)
            }
            <CircleSVG className="footer__ai-circle" />
        </div>
    );
}

function PrototypedFooterState() {
    const state = useUploader('state');
    const prototypeState = useUploader('prototypeState');
    const prototypeNumber = useUploader('prototypeNumber');
    const [text, setText] = useState('Does this feel like a strong starting point for refinement? If not, click Prototype and I’ll reinterpret.');

    const stateChanged = useMemo(() => Boolean(prototypeState) && Object.entries(state).some(([key, value]) => prototypeState && (prototypeState[key as keyof DownloadFormTemplate] !== value)), [state, prototypeState]);

    useEffect(() => {
        if (stateChanged) return setText('I see you’ve made some changes. Click Prototype to update my interpretation.');
        if (prototypeNumber > 1) return setText('Would you like to refine this or explore another take? Use undo to step back, or prototype again for a fresh interpretation.');
        return setText('Does this feel like a strong starting point for refinement? If not, click Prototype and I’ll reinterpret.');
    }, [stateChanged, prototypeNumber]);

    return (
        <div className="footer__state">
            <span>{text}</span>
            <CircleSVG className="footer__ai-circle" />
        </div>
    );
}

function RecognizingFooterState() {
    return (
        <div className="footer__state">
            <span>Breaking this down into details—get ready to fine-tune specific elements.</span>
            <SpinerSVG className="footer__ai-circle footer__ai-spin" />
        </div>
    );
}

const prototypingFooterStateTextes = [
    'Alright, let me picture this… Let’s see how I imagine it.',
    'Translating your sketch into my interpretation—give me a moment.',
    'I think I see what you’re going for… ',
    'Let’s bring your concept into focus. One moment!',
    'Trying my best to match your vision—hope I get it right!',
];

function PrototypingFooterState() {
    const [text, setText] = useState(() => prototypingFooterStateTextes[Math.floor(Math.random() * prototypingFooterStateTextes.length)]);

    const getRandomText = useCallback(() => prototypingFooterStateTextes[Math.floor(Math.random() * prototypingFooterStateTextes.length)], []);

    useEffect(() => {
        const interval = setInterval(() => setText(getRandomText()), 5000);
        return () => clearInterval(interval);
    }, [getRandomText]);

    return (
        <div className="footer__state">
            <span>{text}</span>
            <SpinerSVG className="footer__ai-circle footer__ai-spin" />
        </div>
    );
}

function FooterState() {
    const manager = useManager('self');
    const list = useManager('list');
    const listener = useManager('listener');
    const selectedElements = useManager('selectedElements');
    const onnxState = useEditor('onnxState');
    const editing = useEditor('editing');
    const status = useStatusBar(listener);
    const [prevSelectedElements, setPrevSelectedElements] = useState<Array<Array<Segment | Group>>>([[], []]);

    useEffect(() => setPrevSelectedElements(prev => [prev[1], selectedElements]), [selectedElements]);
    useEffect(() => setPrevSelectedElements([[], []]), [list]);

    const reselect = useCallback(() => manager.selectElements(prevSelectedElements[0]), [manager, prevSelectedElements]);

    return (
        <div className="footer__state">
            <span>{status}</span>
            {onnxState.busy
                ?
                <div className="footer__ai-state">
                    <span>AI is drawing masks</span>
                    <SpinSVG className="footer__ai-state-spin" />
                </div>
                :
                <>{
                    Boolean(!editing)
                    &&
                    <div className="footer__reselect-container">
                        {
                            Boolean(prevSelectedElements[0].length)
                            &&
                            <div id="reselect" onClick={reselect} className="footer__reselect">Select previous</div>
                        }
                        <div className="footer__items">Selected item(s): {selectedElements.length}</div>
                    </div>
                }</>
            }
        </div >
    );
}