import { useStore } from 'effector-react';
import classNames from 'classnames';
import { $shadow } from 'entities/everything';
import './Shadow.scss';


export default function Shadow() {
    const shadow = useStore($shadow);

    return (<div className={classNames({ shadow: true, shadow_showed: shadow })} />)
}
