import EventEmitter from 'eventemitter3';
import { AttachmentType, Group, Manager, Segment } from './Manager';
import { cancelInpaint, inpaint, inpaintCheckStatus, modelInpaint, textInpaint } from 'services/sketch';
import * as utils from './utils';
import { $userId } from 'entities/user';
import { useEffect, useState } from 'react';


export enum InpaintState {
    DEFAULT = 'DEFAULT',
    CHANGED = 'CHANGED',
    EMPTY = 'EMPTY',
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    PAUSE = 'PAUSE',
}

export enum InpaintType {
    TEXT = 'TEXT',
    ATTACHMENT = 'ATTACHMENT',
}

type SegmentInpaintState = {
    payload: string;
    payloadType: InpaintType;
};

export class Inpaint extends EventEmitter {
    private internalId: null | string = null;
    private externalId: null | string = null;
    element: Segment | Group;
    manager: Manager;

    #inpaintState: InpaintState = InpaintState.DEFAULT;
    #lastInpaint: SegmentInpaintState | null = null;
    #inpaintType: InpaintType = InpaintType.TEXT;

    constructor(manager: Manager, element: Segment | Group) {
        super();
        this.element = element;
        this.manager = manager;
        element.addListener('description', () => this.updateInpaintState());
        element.addListener('attachment', () => this.updateInpaintState());
        this.addListener('inpaintType', () => this.updateInpaintState());
    }

    get inpaintState() {
        return this.#inpaintState;
    }
    set inpaintState(value: InpaintState) {
        this.#inpaintState = value;
        this.emit('inpaintState', this.inpaintState);
    }

    get lastInpaint() {
        return this.#lastInpaint;
    }
    set lastInpaint(value: SegmentInpaintState | null) {
        this.#lastInpaint = value;
        this.emit('lastInpaint', this.lastInpaint);
    }

    get inpaintType() {
        return this.#inpaintType;
    }
    set inpaintType(value: InpaintType) {
        this.#inpaintType = value;
        this.emit('inpaintType', this.inpaintType);
    }

    public async run() {
        this.internalId = crypto.randomUUID();
        const id = this.internalId;
        this.inpaintState = InpaintState.IN_PROGRESS;
        const maskWidth = this.manager.width;
        const maskHeight = this.manager.height;
        const { context, canvas } = utils.createCanvasAndContext(maskWidth, maskHeight);
        context.drawImage(this.manager.prototype, 0, 0);
        const buffer = context.getImageData(0, 0, maskWidth, maskHeight);
        const data = buffer.data;

        const prototypeResponse = await fetch(this.manager.prototype.src);
        const prototypeBlob = await prototypeResponse.blob();
        const base_image = new File([prototypeBlob], 'control_image.png', { type: 'image/png' });

        const masks: Array<Uint8Array> = [];
        if (this.element instanceof Segment) masks.push(this.element.mask);
        if (this.element instanceof Group) masks.push(...this.element.segments.map(segment => segment.mask));

        masks.forEach(mask => {
            for (let y = 0; y < maskHeight; y++) {
                for (let x = 0; x < maskWidth; x++) {
                    const maskIndex = y * maskWidth + x;
                    const byte = mask[Math.floor(maskIndex / 8)];
                    const bit = maskIndex % 8;
                    const maskAlpha = (byte & (1 << bit)) ? 255 : 0;

                    if (maskAlpha > 0) {
                        const bufferIndex = (y * maskWidth + x) * 4;
                        data[bufferIndex + 3] = 0;
                    }
                }
            }
        });

        context.putImageData(buffer, 0, 0);
        const blob = await new Promise<Blob>((resolve, reject) => {
            canvas.toBlob(blob => {
                if (blob) resolve(blob);
                else reject(new Error('Blob is null.'));
            });
        });
        if (id !== this.internalId) return;  // check if inpaint was canceled
        const mask_image = new File([blob], 'hole.png', { type: 'image/png' });
        const image = new Image();
        image.src = URL.createObjectURL(mask_image);
        let externalId: string = '';
        try {
            switch (this.inpaintType) {
                case InpaintType.ATTACHMENT:
                    if (!this.element.attachment) throw new Error('Attachment is not exist.');
                    switch (this.element.attachment.type) {
                        case AttachmentType.CUSTOM:
                            this.externalId = await inpaint({ mask_image, base_image, attachment: $userId.getState() + '/' + this.element.attachment.filename });
                            break;
                        case AttachmentType.MODEL:
                            this.externalId = await modelInpaint({ mask_image, base_image, modelName: this.element.attachment.filename, trigger_word: this.element.attachment.note });
                            break;
                    }
                    break;
                case InpaintType.TEXT:
                    this.externalId = await textInpaint({ mask_image, base_image, prompt: this.element.description });
                    break;
                default:
                    throw new Error(`Inpaint type {${this.inpaintType}} is not exist.`);
            }
            externalId = this.externalId;
        } catch (e) {
            this.updateInpaintState();
            return;
        }
        if (id !== this.internalId) return cancelInpaint(externalId);  // check if inpaint was canceled

        let status = 'processing';
        while (status === 'processing') {
            await utils.delay(1000);
            if (id !== this.internalId) return cancelInpaint(externalId);  // check if inpaint was canceled
            status = await inpaintCheckStatus(this.externalId);
            if (id !== this.internalId) return cancelInpaint(externalId);  // check if inpaint was canceled
            if (status === 'errored') {
                this.updateInpaintState();
                this.manager.inpaintingQueue = this.manager.inpaintingQueue.filter(inpaint => inpaint !== this);
                if (this.manager.inpaintingQueue.length) this.manager.inpaintingQueue[0].run();
                return;
            }
        }

        const prototype = new Image();
        prototype.src = `https://sam.quarters-dev.site/inpaint/output/${externalId}`;
        prototype.crossOrigin = '*';
        await prototype.decode();
        this.manager.prototype = prototype;
        if (this.manager.selectedBackground === 'prototype') this.manager.selectBackground('prototype');
        if (this.inpaintType === InpaintType.TEXT) this.lastInpaint = { payload: this.element.description, payloadType: InpaintType.TEXT };
        if (this.inpaintType === InpaintType.ATTACHMENT) {
            if (!this.element.attachment) throw new Error('Attachment is not exist.');
            this.lastInpaint = { payload: this.element.attachment.id, payloadType: InpaintType.ATTACHMENT };
        }
        this.inpaintState = InpaintState.COMPLETED;
        this.manager.history.prototypeCreate();
        this.manager.saveSketch();
        this.manager.inpaintingQueue = this.manager.inpaintingQueue.filter(inpaint => inpaint !== this);
        if (this.manager.inpaintingQueue.length) this.manager.inpaintingQueue[0].run();
    }

    public cancel() {
        this.internalId = null;
        this.externalId = null;
        this.manager.inpaintingQueue = this.manager.inpaintingQueue.filter(inpaint => inpaint !== this);
        if (this.manager.inpaintingQueue.length) this.manager.inpaintingQueue[0].run();
        this.updateInpaintState();
    }

    public pause() {
        this.inpaintState = InpaintState.PAUSE;
    }

    public resume() {
        this.inpaintState = InpaintState.IN_PROGRESS;
    }

    public updateInpaintState() {
        if (!this.#lastInpaint) {
            if (this.#inpaintType === InpaintType.TEXT) {
                if (this.element.description) return this.inpaintState = InpaintState.DEFAULT;
                else return this.inpaintState = InpaintState.EMPTY;
            }

            if (this.#inpaintType === InpaintType.ATTACHMENT) {
                if (this.element.attachment) return this.inpaintState = InpaintState.DEFAULT;
                else return this.inpaintState = InpaintState.EMPTY;
            }
        } else {
            if (this.#lastInpaint.payloadType !== this.#inpaintType) return this.inpaintState = InpaintState.CHANGED;

            if (this.#inpaintType === InpaintType.TEXT) {
                if (this.#lastInpaint.payload === this.element.description) return this.inpaintState = InpaintState.DEFAULT;
                else return this.inpaintState = InpaintState.CHANGED;
            }

            if (this.#inpaintType === InpaintType.ATTACHMENT) {
                if (this.#lastInpaint.payload === this.element.attachment?.id) return this.inpaintState = InpaintState.DEFAULT;
                else return this.inpaintState = InpaintState.CHANGED;
            }
        }
    }

    public toJSON() {
        return {
            inpaintType: this.#inpaintType,
        };
    }
}

export function useInpaint<T extends keyof Pick<Inpaint, 'inpaintState' | 'inpaintType' | 'lastInpaint'>>(inpaint: Inpaint, key: T) {
    const [value, setValue] = useState(inpaint[key]);

    useEffect(() => {
        setValue(inpaint[key]);
        inpaint.addListener(key, setValue);
        return () => void inpaint.removeListener(key, setValue);
    }, [inpaint, key]);

    return value;
}
