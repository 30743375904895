import { useCallback } from 'react';
import Button from 'Components/Button';
import LeftSidePanel from '../LeftSidePanel';
import { ReactComponent as AssistentSVG } from './icons/assistent.svg';
import { setLeftSidePanel, LeftSidePanel as LeftSidePanelEnum } from 'entities/leftSidePanel';
import { useManager } from 'entities/sketch/Manager';
import { RightSide, setRightSide } from 'entities/router';
import './IrreversibleStep.scss';


export default function IrreversibleStep() {
    const { initiator } = useManager('self');

    const proceed = useCallback(() => {
        initiator.recognize();
        setLeftSidePanel(LeftSidePanelEnum.NONE);
        setRightSide(RightSide.LIST);
    }, [initiator]);

    return (
        <LeftSidePanel>
            <div className="irreversible-step">
                <div className="irreversible-step__title-container">
                    <AssistentSVG />
                    <div className="irreversible-step__title">Irreversible step!</div>
                </div>
                <div className="irreversible-step__text">Next, you’ll mask elements that may need adjustments, define them in the table, and I'll do my best to redraw them accordingly.</div>
                <div className="irreversible-step__text">Check everything carefully before proceeding, as this step is irreversible.</div>
                <div className="irreversible-step__buttons">
                    <Button size="medium" color="dark" onClick={proceed}>PROCEED</Button>
                    <Button size="medium" color="white" onClick={() => setLeftSidePanel(LeftSidePanelEnum.NONE)}>BACK</Button>
                </div>
            </div>
        </LeftSidePanel>
    );
}



