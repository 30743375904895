import { useCallback, useState } from 'react';
import { ReactComponent as AssistantSVG } from './icons/assistant.svg';
import Button from 'Components/Button';
import Checkbox from 'Components/Checkbox';
import { setShadow } from 'entities/everything';
import { useManager } from 'entities/sketch/Manager';
import './MainNotice.scss';


export default function MainNotice() {
    const { engine } = useManager('self');
    const [neverShowAgain, setNeverShowAgain] = useState(true);

    const hide = useCallback(() => {
        if (neverShowAgain) localStorage.setItem('noticeHidden', 'true');
        else localStorage.setItem('noticeHidden', 'false');
        engine.highlightedSprite = null;
        setShadow(null);
    }, [neverShowAgain, engine]);

    return (
        <div className="main_notice__note-tooltip">
            <div className="main_notice__note-tooltip-top-line">
                <AssistantSVG className="main_notice__note-tooltip-icon" />
                <div className="main_notice__note-tooltip-text">
                    <div>MASK ANYTHING AND I REDRAW</div>
                    <div>IT THE WAY YOU DEFINE</div>
                </div>
            </div>
            <div className="main_notice__note-tooltip-bottom">
                <Button size="medium" color="white" onClick={hide}>OK</Button>
                <Checkbox checked={neverShowAgain} onChange={(value) => setNeverShowAgain(value)} className="main_notice__note-tooltip-bottom-checkbox" />
                <div className="main_notice__note-tooltip-checkbox-label">Never show again</div>
            </div>
        </div>
    );
}
