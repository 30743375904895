import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { Draggable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { ReactComponent as DNDSVG } from './icons/dnd.svg';
import { ReactComponent as CheckBoxSVG } from './icons/checkbox.svg';
import { ReactComponent as CheckBoxCheckedSVG } from './icons/checkbox_checked.svg';
import { ReactComponent as CheckBoxDisabledSVG } from './icons/checkbox_disabled.svg';
import { ReactComponent as CrossSVG } from './icons/cross.svg';
import { ReactComponent as TextInpaintActiveSVG } from './icons/text-inpaint_active.svg';
import { ReactComponent as AttachmentInpaintSVG } from './icons/attachment-inpaint.svg';
import { ReactComponent as TextInpaintSVG } from './icons/text-inpaint.svg';
import { ReactComponent as AttachmentInpaintActiveSVG } from './icons/attachment-inpaint_active.svg';
import { ReactComponent as RemoveSVG } from './icons/remove.svg';
import { Segment, useEditor, useSegment, useManager } from 'entities/sketch/Manager';
import * as Listeners from 'entities/sketch/Listeners';
import { setElementForAttach } from 'entities/library';
import { $userId } from 'entities/user';
import { InpaintState, InpaintType, useInpaint } from 'entities/sketch/Inpaint';
import { ListType } from '../List';
import './SegmentElement.scss';


type SegmentElementProps = {
    segment: Segment;
    index: number;
    matchDefinition: undefined | ((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void);
    listType: ListType;
};

enum CheckBoxState {
    REGULAR = 'REGULAR',
    CHECKED = 'CHECKED',
    DISABLED = 'DISABLED',
}

export default function SegmentElement({ segment, index, matchDefinition, listType }: SegmentElementProps) {
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const selectedElements = useManager('selectedElements');
    const hoveredElements = useManager('hoveredElements');
    const inpaintingQueue = useManager('inpaintingQueue');
    const listener = useManager('listener');
    const editing = useEditor('editing');
    const color = useSegment(segment, 'color');
    const attachment = useSegment(segment, 'attachment');
    const inpaintState = useInpaint(segment.inpaint, 'inpaintState');
    const inpaintType = useInpaint(segment.inpaint, 'inpaintType');
    const [checkBoxState, setCheckBoxState] = useState<CheckBoxState>(CheckBoxState.REGULAR);

    const handleSelect = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (inpaintingQueue.length) return;
        if (matchDefinition) return matchDefinition(e, segment.id);

        if (editingGroup) {
            const group = manager.editingGroup;
            if (!group) throw new Error('Group is not being edited.');
            if (!e.ctrlKey && !e.metaKey) return;
            if (group.segments.includes(segment)) throw new Error('Group has this segment.');
            else manager.addSegmentToGroup(segment, group, group.segments.length);
            return;
        }
        if (editing) return;
        let target: unknown = e.target;

        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (target.getAttribute('data-type') === 'action') return;
            target = target.parentElement;
        }

        manager.selectOneElement(segment);
    }, [segment, manager, editingGroup, editing, inpaintingQueue, matchDefinition]);

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {
        if (segment.inpaint.inpaintState === InpaintState.COMPLETED) segment.inpaint.updateInpaintState();
        if (matchDefinition && (segment.description || segment.attachment) && (!manager.matchDefinition || !manager.matchDefinition.full)) manager.matchDefinition = { element: segment, full: false, x: e.clientX, y: e.clientY };
        manager.hoveredElements = [segment];
    }, [segment, manager, matchDefinition]);

    const onMouseLeave = useCallback(() => {
        if (manager.matchDefinition && !manager.matchDefinition.full && manager.matchDefinition.element === segment) manager.matchDefinition = null;
        if (manager.hoveredElements[0] === segment) manager.hoveredElements = [];
    }, [segment, manager]);

    const selectAttachmentInpaint = useCallback(() => {
        if (!attachment) setElementForAttach(segment);
        else segment.inpaint.inpaintType = InpaintType.ATTACHMENT;
    }, [segment, attachment]);

    const segmentHovered = useMemo(() => hoveredElements.includes(segment), [hoveredElements, segment]);
    const segmentSelected = useMemo(() => selectedElements.includes(segment), [selectedElements, segment]);

    useEffect(() => {
        if (inpaintingQueue.includes(segment.inpaint)) return setCheckBoxState(CheckBoxState.DISABLED);
        if (segmentSelected) return setCheckBoxState(CheckBoxState.CHECKED);
        return setCheckBoxState(CheckBoxState.REGULAR);
    }, [matchDefinition, editing, editingGroup, segmentSelected, inpaintingQueue, segment]);

    const changeInpaintTypeDisabled = useMemo(() => {
        if (inpaintingQueue.includes(segment.inpaint)) return true;
        if (editing) return true;
        if (editingGroup) return true;
        if (listener instanceof Listeners.MatchDefinition) return true;
        return false;
    }, [inpaintingQueue, editing, editingGroup, listener, segment]);

    const DNDDisabled = useMemo(() => {
        if (editingGroup) return true;
        if (inpaintingQueue.includes(segment.inpaint) && listType === ListType.REGULAR) return true;
        if (listener instanceof Listeners.MatchDefinition) return true;
        return false;
    }, [editingGroup, inpaintingQueue, listType, listener, segment]);

    return (
        <Draggable draggableId={segment.id} index={index}>
            {(provided, snapshot) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className={classNames({
                        segment: true,
                        segment_hovered: segmentHovered,
                    })}
                    onClick={e => handleSelect(e)}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <div className="segment__dnd-cell">
                        <div {...provided.dragHandleProps} tabIndex={-1} className={classNames({ 'segment__dnd-handle-container': true, disabled: DNDDisabled })}>
                            <DNDSVG data-type="action" />
                        </div>
                    </div>
                    <div className="segment__check-box-cell">
                        {checkBoxState === CheckBoxState.REGULAR && <CheckBoxSVG className="pointer" onClick={() => manager.selectElement(segment)} data-type="action" />}
                        {checkBoxState === CheckBoxState.CHECKED && <CheckBoxCheckedSVG className="pointer" onClick={() => manager.unselectElement(segment)} data-type="action" />}
                        {checkBoxState === CheckBoxState.DISABLED && <CheckBoxDisabledSVG />}
                    </div>
                    <div className="segment__color-cell">
                        <div className="segment__color" style={{ backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})` }} onClick={() => manager.changeToRandomColor(segment)} data-type="action" />
                        <div className="segment__name">{segment.name.toString().padStart(3, '0')}</div>
                    </div>
                    <div className="segment__inpaint-cell">
                        {inpaintState === InpaintState.DEFAULT && <div className="segment__icon segment__inpaint-icon" data-type="action" onClick={() => manager.addToInpaintingQueue(segment.inpaint)} />}
                        {inpaintState === InpaintState.CHANGED && <div className="segment__icon segment__inpaint-changed-icon" data-type="action" onClick={() => manager.addToInpaintingQueue(segment.inpaint)} />}
                        {inpaintState === InpaintState.EMPTY && <div className="segment__icon segment__inpaint-empty-icon" />}
                        {inpaintState === InpaintState.COMPLETED && <div className="segment__icon segment__inpaint-completed-icon" />}
                        {inpaintState === InpaintState.PENDING && <div className="segment__icon segment__inpaint-pending-icon" data-type="action" onClick={() => manager.cancelInpainting(segment.inpaint)} />}
                        {inpaintState === InpaintState.IN_PROGRESS && <div className="segment__icon segment__inpaint-in-progress-icon" data-type="action" onClick={() => manager.cancelInpainting(segment.inpaint)} />}
                        {inpaintState === InpaintState.PAUSE && <div className="segment__icon segment__inpaint-pause-icon" data-type="action" onClick={() => manager.cancelInpainting(segment.inpaint)} />}
                    </div>
                    <div className="segment__input-cell">
                        {inpaintType === InpaintType.TEXT && <TextInput segment={segment} />}
                        {inpaintType === InpaintType.ATTACHMENT && <Attachment segment={segment} />}
                    </div>
                    <div className="segment__inpaint-type-cell">
                        {inpaintType === InpaintType.TEXT &&
                            <>
                                <TextInpaintActiveSVG className={classNames('segment__text-inpaint-icon', { disabled: changeInpaintTypeDisabled })} />
                                <div className={classNames('segment__attachment-inpaint-icon segment__icon', { disabled: changeInpaintTypeDisabled })} onClick={selectAttachmentInpaint} data-type="action">
                                    <AttachmentInpaintSVG className="segment__icon" />
                                </div>
                            </>
                        }
                        {inpaintType === InpaintType.ATTACHMENT &&
                            <>
                                <div className={classNames('segment__text-inpaint-icon segment__icon pointer', { disabled: changeInpaintTypeDisabled })} onClick={() => segment.inpaint.inpaintType = InpaintType.TEXT} data-type="action">
                                    <TextInpaintSVG className="segment__icon" />
                                </div>
                                <AttachmentInpaintActiveSVG className={classNames('segment__attachment-inpaint-icon', { disabled: changeInpaintTypeDisabled })} />
                            </>
                        }
                    </div>
                    <div className="segment__remove-cell">
                        <div className={classNames({ segment__icon: true, pointer: true, hidden: !segmentHovered })} onClick={() => manager.removeSegments([segment])} data-type="action">
                            <RemoveSVG className="segment__icon" />
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}

type TextInputProps = {
    segment: Segment;
};

function TextInput({ segment }: TextInputProps) {
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const inpaintingQueue = useManager('inpaintingQueue');
    const listener = useManager('listener');
    const description = useSegment(segment, 'description');
    const [prevDescription, setPrevDescription] = useState(description);

    const onBlur = useCallback(() => {
        if (prevDescription === description) return;
        else {
            manager.history.create();
            manager.saveSketch();
        }
    }, [manager, prevDescription, description]);

    const onClick = useCallback(() => {
        const input = document.getElementById(`segment_${segment.id}_input`);
        if (!input) throw new Error('Input is not found.');
        input.focus();
    }, [segment]);

    const disabled = useMemo(() => Boolean(editingGroup || inpaintingQueue.includes(segment.inpaint)), [editingGroup, inpaintingQueue, segment]);

    return (
        <div className={classNames('segment__text-area', { disabled: listener instanceof Listeners.MatchDefinition })} data-type="action" onClick={() => onClick()}>
            <TextareaAutosize
                maxRows={4}
                id={`segment_${segment.id}_input`}
                value={description}
                onChange={e => segment.description = e.currentTarget.value}
                className={classNames('segment__description', { disabled })}
                placeholder="Type or attach to define"
                onFocus={() => setPrevDescription(description)}
                onBlur={onBlur}
                disabled={disabled}
            />
        </div>
    );
}

type AttachmentProps = {
    segment: Segment;
};

function Attachment({ segment }: AttachmentProps) {
    const userId = useStore($userId);
    const listener = useManager('listener');
    const attachment = useSegment(segment, 'attachment');

    const src = useMemo(() => attachment?.getImageSrc(userId) || '', [attachment, userId]);

    if (!attachment) throw new Error('Attachment is not defined.');

    return (
        <div className={classNames('segment__attachment', { disabled: listener instanceof Listeners.MatchDefinition })}>
            <img src={src} alt="attachment preview" className="segment__attachment-image" />
            <div className="segment__attachment-filename">{attachment.originalname}</div>
            <CrossSVG className="segment__attachment-remove" onClick={() => segment.unattach()} data-type="action" />
        </div>
    );
}
