import * as R from 'ramda';
import { produce } from 'immer';
import { createEffect, createEvent, createStore } from 'effector';
import { Sketch, copySketch, deleteSketch, loadSketches } from 'services/sketch';

export const $sketches = createStore<Array<Sketch>>([]);

export const loadSketchesFx = createEffect(loadSketches);
export const deleteSketchFx = createEffect(deleteSketch);
export const copySketchFx = createEffect(copySketch);

export const updateSketchNameEvent = createEvent<{ id: string, name: string }>();

$sketches
    .on(loadSketchesFx.doneData, R.nthArg(1))
    .on(deleteSketchFx.done, (state, payload) => state.filter(sketch => sketch.id !== payload.params))
    .on(updateSketchNameEvent, (state, payload) => produce(state, draft => {
        const sketch = draft.find(sketch => sketch.id === payload.id);
        if (sketch) sketch.name = payload.name;
    }))
    .on(copySketchFx.doneData, (state, payload) => [payload, ...state]);
