import { createStore, createEffect, createEvent } from 'effector';
import * as R from 'ramda';
import { Group, Segment } from 'entities/sketch/Manager';
import { FileData, Model, loadModels, upload } from 'services/library';

export const $library = createStore<Array<FileData>>([]);
export const $models = createStore<Array<Model>>([]);
export const $elementForAttach = createStore<Segment | Group | null>(null);

export const setElementForAttach = createEvent<Segment | Group | null>();
export const addModel = createEvent<Model>();

export const uploadFx = createEffect(upload);
export const loadModelsFx = createEffect(loadModels);

$elementForAttach
    .on(setElementForAttach, (_, value) => value);

$models
    .on(loadModelsFx.doneData, R.nthArg(1))
    .on(addModel, (state, model) => [...state, model]);

loadModelsFx();
setInterval(() => loadModelsFx(), 1000 * 60);