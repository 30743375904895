import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Group, Segment, useManager } from 'entities/sketch/Manager';
import * as Listeners from 'entities/sketch/Listeners';
import { ReactComponent as CreateEditGroupOfSimilarSVG } from './icons/create_edit_group_of_similar.svg';


export function EditGroupButton() {
    const manager = useManager('self');
    const selectedElements = useManager('selectedElements');
    const inpaintingQueue = useManager('inpaintingQueue');

    const disabled = useMemo(() => {
        if (selectedElements.length === 0) return false;
        if (selectedElements.some(element => element instanceof Group) && selectedElements.length !== 1) return true;

        if (selectedElements.some(element => inpaintingQueue.includes(element.inpaint))) return true;
    }, [selectedElements, inpaintingQueue]);

    const handleCreateGroup = useCallback(async () => {
        if (selectedElements.some(element => element instanceof Group)) throw new Error('Selected elements have Group.');
        const group = manager.createGroup(selectedElements as Array<Segment>);
        manager.editingGroup = group;
        manager.listener = new Listeners.EditGroup(manager);
    }, [selectedElements, manager]);

    const handleEdit = useCallback(() => {
        const group = selectedElements[0];
        if (!(group instanceof Group)) throw new Error('Element is not group.');
        manager.editingGroup = group;
        manager.listener = new Listeners.EditGroup(manager);
        manager.unselectAllElements();
    }, [selectedElements, manager]);

    const handleClick = useCallback(() => {
        if (selectedElements[0] instanceof Group && selectedElements.length === 1) return handleEdit();
        else handleCreateGroup();
    }, [selectedElements, handleCreateGroup, handleEdit]);

    return (
        <div
            id="edit_group"
            className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': disabled })}
            onClick={handleClick}
        >
            <CreateEditGroupOfSimilarSVG />
            <div className="list__toolbar-button-text">CREATE/EDIT<br />GROUP OF SIMILAR</div>
        </div>
    );
}
