import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useHotKey } from 'hooks';
import { Sketch, updateSketchName } from 'services/sketch';
import { copySketchFx, deleteSketchFx, updateSketchNameEvent } from 'entities/sketches';
import './SketchList.scss';


type SketchListProps = {
    sketch: Sketch;
    isSelected: boolean;
    setSelectedSketch: React.Dispatch<React.SetStateAction<Sketch | null | undefined>>
    openPrototype: (sketch: Sketch) => void;
};

export default function SketchList({ sketch, isSelected, setSelectedSketch, openPrototype }: SketchListProps) {
    const [isEdited, setIsEdited] = useState<boolean>(false);

    useHotKey('Escape', () => setIsEdited(false));

    const onClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let timeout: NodeJS.Timeout | undefined;
        const target = e.target as unknown;
        if (target instanceof Element && target.getAttribute('data-type') !== 'sketch') return;
        if (isSelected) timeout = setTimeout(() => setIsEdited(true), 600);
        else setSelectedSketch(sketch);

        return () => clearTimeout(timeout);
    }, [isSelected, setSelectedSketch, sketch]);

    const remove = useCallback(() => {
        deleteSketchFx(sketch.id);
        setSelectedSketch(prev => {
            if (prev === sketch) return null;
            return prev;
        });
    }, [sketch, setSelectedSketch]);

    if (isEdited) return <SketchNameInput sketch={sketch} close={() => setIsEdited(false)} />;
    else return (
        <div
            data-type="sketch"
            className={classNames('sketch-list', { 'sketch-list__selected': isSelected })}
            onClick={onClick}
            onDoubleClick={() => openPrototype(sketch)}
        >
            <div className="sketch-list__name" data-type="sketch">{sketch.name}</div>
            <div className="sketch-list__icons_wrapper">
                <div className={classNames({
                    'sketch-list__icon': true,
                    'rename_icon': true,
                })} onClick={() => setIsEdited(true)} />
                <div className={classNames({
                    'sketch-list__icon': true,
                    'copy_icon': true,
                })} onClick={() => copySketchFx(sketch.id)} />
                <div className={classNames({
                    'sketch-list__icon': true,
                    'delete_icon': true,
                })} onClick={() => remove()} />
            </div>
        </div>
    );
}

type SketchNameInputProps = {
    sketch: Sketch;
    close: () => void;
};

function SketchNameInput({ sketch, close }: SketchNameInputProps) {
    const [name, setName] = useState(sketch.name);

    const handleInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key !== 'Enter') return;
        if (name.trim() !== '') {
            updateSketchNameEvent({ name, id: sketch.id });
            updateSketchName({ name, sketchId: sketch.id });
        }
        close();
    }, [name, sketch, close]);

    return (
        <input
            className="sketch-list__name-input"
            value={name}
            onChange={e => setName(e.target.value)}
            onKeyDown={handleInputKeyDown}
            autoFocus={true}
            maxLength={36}
            onBlur={() => close()}
        />
    );
}
