import { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { $email } from 'entities/user';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { $route, goTo, RoutePath } from 'entities/router';
import { ManagerInitStep, useInitiator, useManager } from 'entities/sketch/Manager';
import { setHint } from 'entities/hint';
import { copySketchFx } from 'entities/sketches';
import { updateSketchName } from 'services/sketch';
import { useHotKey } from 'hooks';
import './Header.scss';


type HeaderProps = {
    type?: 'home' | 'sketch' | 'upload';
};

enum SketchMenuOptions {
    'GO TO IO-BURO' = 'GO TO IO-BURO',
    'CREATE NEW' = 'CREATE NEW',
    'SAVE AS COPY' = 'SAVE AS COPY',
    EXPORT = 'EXPORT',
}

export default function Header({ type = 'home' }: HeaderProps) {
    const email = useStore($email);
    const [state, setState] = useState('');
    const [nameEdit, setNameEdit] = useState<boolean>(false)
    const name = useManager('name');

    useEffect(() => setState(''), [type]);

    return (
        <div className="upload__header">
            <HeaderMenu setState={setState} />
            {type === 'sketch' && <div className="upload__header-state" onDoubleClick={() => setNameEdit(true)}>
                {nameEdit
                    ?
                    <NameInput cancel={() => setNameEdit(false)} />
                    :
                    <p>{name + state}</p>
                }
                <div />
            </div>}
            {!email ?
                <div className="sketch__header-register" onClick={() => setLeftSidePanel(LeftSidePanel.AUTH)}>sign in</div>
                :
                <div
                    className="upload__register"
                    onClick={() => setLeftSidePanel(LeftSidePanel.ACCOUNT)}
                    id="upload__register"
                    onMouseEnter={() => setHint({ id: 'upload__register' })}
                    onMouseLeave={() => setHint(null)}
                >
                    {email}
                </div>
            }
        </div>
    );
}

type HeaderMenuProps = {
    setState: React.Dispatch<React.SetStateAction<string>>;
};

function HeaderMenu({ setState }: HeaderMenuProps) {
    const select = useRef<HTMLDivElement>(null);
    const manager = useManager('self');
    const [, setActive] = useState(false);
    const route = useStore($route);
    const initStep = useInitiator('initStep');

    const selectMenuOption = useCallback((value: SketchMenuOptions) => {
        select.current?.blur();
        switch (value) {
            case SketchMenuOptions['GO TO IO-BURO']:
                window.open('https://quarters-dev.site/', '_self');
                break;
            case SketchMenuOptions['CREATE NEW']:
                window.open('https://demo.quarters-dev.site/', '_self');
                break;
            case SketchMenuOptions['SAVE AS COPY']:
                setState(' (SAVING...)');
                copySketchFx(manager.id).then(result => {
                    setState(` (SAVED ${new Date().toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })})`);
                    manager.id = result.id;
                    manager.name = result.name;
                });
                break;
            case SketchMenuOptions.EXPORT:
                manager.saveRender();
                break;
            default:
                return console.error(value, 'is not implemented');
        }
    }, [manager, setState]);

    const handleClick = useCallback(() =>
        setActive(prev => {
            if (!prev) select.current?.focus();
            else select.current?.blur();
            return !prev;
        }), []);

    return (
        <div ref={select} tabIndex={0} className="header__menu">
            <div className="header__menu-select" onClick={handleClick}>
                <div className="header__menu-select-icon" />
                <div className="header__menu-select-arrow" />
            </div>
            <div className={classNames('header__menu-home', { 'header__menu-home_disabled': route === RoutePath.HOME })} onClick={() => goTo(RoutePath.HOME)} />
            <div className="header__menu-select-items">
                <div className="header__menu-select-item" onClick={() => selectMenuOption(SketchMenuOptions['GO TO IO-BURO'])}>{SketchMenuOptions['GO TO IO-BURO']}</div>
                <div className="header__menu-select-item" onClick={() => selectMenuOption(SketchMenuOptions['CREATE NEW'])}>{SketchMenuOptions['CREATE NEW']}</div>
                <div className={classNames('header__menu-select-item', { 'header__menu-select-item_disabled': initStep !== ManagerInitStep.READY })} onClick={() => selectMenuOption(SketchMenuOptions['SAVE AS COPY'])}>{SketchMenuOptions['SAVE AS COPY']}</div>
                <div className={classNames('header__menu-select-item', { 'header__menu-select-item_disabled': initStep !== ManagerInitStep.READY })} onClick={() => selectMenuOption(SketchMenuOptions.EXPORT)}>{SketchMenuOptions.EXPORT}</div>
            </div>
        </div>
    );
}

type NameInputProps = {
    cancel: () => void;
};

function NameInput({ cancel }: NameInputProps) {
    const manager = useManager('self');

    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [value, setValue] = useState(manager.name);

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            const spanWidth = spanRef.current.offsetWidth;
            inputRef.current.style.width = `${spanWidth + 10}px`;
        }
    }, [value]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (value.trim() !== "") {
                manager.name = value;
                updateSketchName({ name: value, sketchId: manager.id });
            }
            cancel();
        }
    }, [cancel, manager, value]);

    useHotKey('Escape', cancel);

    return (
        <div className="settings_title-input">
            <span className="size-span" ref={spanRef}>{value}</span>
            <input
                value={value}
                onChange={e => setValue(e.target.value)}
                ref={inputRef}
                onKeyDown={handleKeyDown}
                maxLength={36}
                autoFocus={true}
                onBlur={cancel}
            />
        </div>
    );
}
