import { useState } from 'react';
import { Render } from 'services/sketch';
import config from 'config';
import { ReactComponent as ExitSvg } from './icons/exit.svg';
import { ModalType, openModal } from 'entities/modal';
import { useManager } from 'entities/sketch/Manager';
import './RenderElement.scss';


interface RenderProps {
    render: Render;
    isSelected: boolean;
    handleCheckboxClick: (id: string) => void;
}

export default function RenderElement({ render, isSelected, handleCheckboxClick }: RenderProps) {
    const manager = useManager('self');
    const [preview, setPreview] = useState(false);

    return (
        <div className="render__element-wrapper">
            <img className="render__element-img" src={config.backendUrl + '/api/render/' + render.id + '.png'} alt="render" crossOrigin="anonymous" />
            <div className={`render__checkbox ${isSelected ? 'render__checkbox_active' : ''}`} onClick={() => handleCheckboxClick(render.id)}>
                {isSelected && <div className="render__checkbox_selected" />}
            </div>
            <div className="render__element-settings_cover" />
            <div className="render__element-settings">
                {preview && <Preview src={config.backendUrl + '/api/render/' + render.id + '.png'} exit={() => setPreview(false)} />}
                <p onClick={() => setPreview(true)}>preview</p>
                <p onClick={() => manager.loadRender(render.id)}>download</p>
                <p onClick={() => openModal({ type: ModalType.SHARE, props: { id: render.id } })}>Share</p>
            </div>
        </div>
    );
}

type PreviewProps = {
    src: string;
    exit: () => void;
};

function Preview({ src, exit }: PreviewProps) {
    return (
        <div className="render-preview">
            <div className="render-preview__container">
                <ExitSvg className="render-preview__exit-button" onClick={exit} />
                <img className="render-preview__image" src={src} alt="preview" crossOrigin="anonymous" />
            </div>
        </div>
    );
}
