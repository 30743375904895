import { useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { produce } from 'immer';
import Button from 'Components/Button';
import { useUploader } from 'entities/sketch/Uploader';
import { useManager } from 'entities/sketch/Manager';
import './Objects.scss';


export default function Objects() {
    const { uploader } = useManager('self');
    const objects = useUploader('objects');

    const remove = useCallback((id: string, index: number) => uploader.objects = produce(uploader.objects, draft => {
        if (index === 0) draft[id] = '';
        else delete draft[id];
    }), [uploader]);

    const onChange = useCallback((id: string, value: string) => uploader.objects = produce(uploader.objects, draft => {
        draft[id] = value;
    }), [uploader]);

    return (
        <div className="objects">
            <div className="objects__col">
                {
                    Object.entries(objects).map(([key, value], index) => (
                        <div className="objects__text-field-wrapper" key={key}>
                            <TextareaAutosize
                                className="objects__text-field"
                                placeholder="Type what you want to add"
                                value={value}
                                onChange={(e) => onChange(key, e.target.value)}
                            />
                            <div className="objects__text-field-remove-icon" onClick={() => remove(key, index)} />
                        </div>
                    ))
                }
                <Button color="white" size="medium" className="objects__button" onClick={() => uploader.objects = { ...objects, [crypto.randomUUID()]: '' }}>
                    <div className="objects__add-button-icon" />
                    <div>ADD</div>
                </Button>
            </div>
        </div >
    );
}