import { useCallback, useEffect, useRef, useState } from 'react';
import { setShadow } from 'entities/everything';
import './NoteIdentifyButton.scss';


type NoteIdentifyButtonProps = {
    children: string;
};

export default function NoteIdentifyButton({ children }: NoteIdentifyButtonProps) {
    const [zIndex, setZIndex] = useState<null | string>(null);
    const [opacity, setOpacity] = useState<null | string>(null);
    const ref = useRef<HTMLSpanElement>(null);
    const timer = useRef<NodeJS.Timeout | undefined>(undefined)

    const show = useCallback(() => {
        const element = document.getElementById('identify_button');
        if (!element) return;
        const span = ref.current;
        if (!span) throw new Error('Ref is not set');

        if (element.style.zIndex) setZIndex(element.style.zIndex);
        if (element.style.opacity) setOpacity(element.style.opacity);
        element.style.zIndex = '101';
        element.style.opacity = '1';
        setShadow('identify_button');
        element.classList.add('under-note');
        timer.current = setTimeout(() => {
            const animation = 'identify-button-grwoing 1.6s ease-in-out forwards';
            const target = document.getElementById('identify_button_animation-container');
            if (!target) return;
            target.style.animation = animation;
        }, 930);
    }, []);

    const hide = useCallback(() => {
        if (timer.current) clearTimeout(timer.current);
        const target = document.getElementById('identify_button_animation-container');
        if (!target) return;
        target.style.animation = '';

        setShadow(null);
        const element = document.getElementById('identify_button');
        if (!element) return;

        if (!zIndex) element.style.zIndex = '';
        else element.style.zIndex = zIndex;
        if (!opacity) element.style.opacity = '';
        else element.style.opacity = opacity;
        element.classList.remove('under-note');
    }, [zIndex, opacity]);

    useEffect(() => hide, [hide]);

    return (<span className="note-identify-button" ref={ref} onMouseEnter={show} onMouseLeave={hide}>{children}</span>);
}