import { createStore, createEffect } from 'effector';
import { recognize, loadMask, loadSketch, baseRender, baseRenderCheckStatus } from 'services/sketch';
import { Manager } from './Manager';
import { demoSketch } from './constants';


export const $manager = createStore<Manager>(new Manager(demoSketch));

export const recognizeFx = createEffect(recognize);
export const loadMaskFx = createEffect(loadMask);
export const loadSketchFx = createEffect(loadSketch);
export const makePrototypeFx = createEffect(baseRender);
export const checkPrototypeStatusFx = createEffect(baseRenderCheckStatus);
