import { useCallback, useEffect, useState } from 'react';
import { Attachment, Group, Segment, useGroup, useManager, useSegment } from 'entities/sketch/Manager';
import { ReactComponent as AttachmentSVG } from './icons/attachment.svg';
import { ReactComponent as ColorfulAttachmentSVG } from './icons/colorful-attachment.svg';
import { ReactComponent as DescriptionSVG } from './icons/description.svg';
import { ReactComponent as ColorfulDescriptionSVG } from './icons/colorful-description.svg';
import { Inpaint, InpaintType, useInpaint } from 'entities/sketch/Inpaint';
import './MatchDefinitionHint.scss';


export default function MatchDefinitionHintWrapper() {
    const matchDefinition = useManager('matchDefinition');
    if (!matchDefinition) return null;

    const { element, full } = matchDefinition;

    if (element instanceof Segment) return (<MatchDefinitionSegmentHintWrapper element={element} full={full} />);
    if (element instanceof Group) return (<MatchDefinitionGroupHintWrapper element={element} full={full} />);
    throw new Error('Element is uknown.');
}

type MatchDefinitionSegmentHintWrapperProps = {
    element: Segment;
    full: boolean;
};

function MatchDefinitionSegmentHintWrapper({ element, full }: MatchDefinitionSegmentHintWrapperProps) {
    const description = useSegment(element, 'description');
    const attachment = useSegment(element, 'attachment');

    return (<MatchDefinitionHint description={description} attachment={attachment} inpaint={element.inpaint} full={full} />);
}

type MatchDefinitionGroupHintWrapperProps = {
    element: Group;
    full: boolean;
};

function MatchDefinitionGroupHintWrapper({ element, full }: MatchDefinitionGroupHintWrapperProps) {
    const description = useGroup(element, 'description');
    const attachment = useGroup(element, 'attachment');

    return (<MatchDefinitionHint description={description} attachment={attachment} inpaint={element.inpaint} full={full} />);
}

type MatchDefinitionHintProps = {
    description: string;
    attachment: Attachment | null;
    inpaint: Inpaint;
    full: boolean;
};

function MatchDefinitionHint({ description, attachment, inpaint, full }: MatchDefinitionHintProps) {
    const manager = useManager('self');
    const inpaintType = useInpaint(inpaint, 'inpaintType');
    const [position, setPosition] = useState({ left: 0, top: 0 });
    const [transform, setTransform] = useState('translate(0px, 0px)');
    const [visibility, setVisibility] = useState<'hidden' | 'visible'>('hidden');

    const setStyles = useCallback(({ x, y }: { x: number, y: number }) => {
        const { innerWidth, innerHeight } = window;
        let translateX = '0px';
        let translateY = '0px';
        let left = 0;
        let top = 0;
        if (x > innerWidth / 2) {
            translateX = '-100%';
            left = x - 16;
        } else {
            left = x + 23;
        }

        if (y > innerHeight / 2) {
            translateY = '-100%';
            top = y - 28;
        } else {
            top = y + 7;
        }
        setPosition({ left, top });
        setTransform(`translate(${translateX}, ${translateY})`);
        setVisibility('visible');
    }, []);

    useEffect(() => {
        function fn(e: MouseEvent) {
            setStyles({ x: e.clientX, y: e.clientY });
        }

        document.addEventListener('mousemove', fn);
        return () => document.removeEventListener('mousemove', fn);
    }, [setStyles]);

    useEffect(() => {
        const matchDefinition = manager.matchDefinition;
        if (!matchDefinition) return;

        setStyles(matchDefinition);
    }, [manager, setStyles]);

    return (
        <div className="match-definition-hint" style={{ ...position, transform, visibility }}>
            {
                inpaintType === InpaintType.TEXT &&
                <div className="match-definition-hint__container">
                    {full ? <ColorfulDescriptionSVG className="match-definition-hint__icon" /> : <DescriptionSVG className="match-definition-hint__icon" />}
                    <div className="match-definition-hint__description">{description}</div>
                </div>
            }
            {
                inpaintType === InpaintType.ATTACHMENT &&
                <div className="match-definition-hint__container">
                    {full ? <ColorfulAttachmentSVG className="match-definition-hint__icon" /> : <AttachmentSVG className="match-definition-hint__icon" />}
                    <div className="match-definition-hint__description">{attachment?.originalname}</div>
                </div>
            }
            <div className="match-definition-hint__help">{
                full
                    ?
                    <span>Click to match definition</span>
                    :
                    <span>Click to copy definition</span>
            }</div>
        </div>
    );
}
