import { useState, useRef, useEffect } from 'react';
import { useStore } from 'effector-react';
import { WhatIsSection, ChoosingImageSection, MaskSelectionSection, RenderingSection, InterfaceSection, CreateMaskSection, DescribeObjectsSection, GroupSection, AccountSection } from './Sections/Sections';
import { HelpSections, readMoreItems } from "./helpers/helpers";
import { $route, RoutePath } from 'entities/router';
import { $helpSection, $isExtendedHelp, setHelpSection, setIsExtendedHelp } from 'entities/help';
import { setHint } from 'entities/hint';
import useScrollDetection from 'services/useScrollDetection';
import './Help.scss';


export default function Help() {
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const extendedHelpRef = useRef<HTMLDivElement>(null);
    const [hasScroll, setHasScroll] = useState(false);
    const [showAgain, setShowAgain] = useState<boolean>(true);
    const helpSection = useStore($helpSection);
    const route = useStore($route);
    const isExtendedHelp = useStore($isExtendedHelp);
    const isScrolling = useScrollDetection({ ref: extendedHelpRef })

    const handleScrollDown = () => {
        const currentListRef = extendedHelpRef.current;
        if (currentListRef) {
            currentListRef.scrollTo({
                top: currentListRef.scrollTop + 200,
                behavior: 'smooth'
            });
        }
    };

    const closeHelp = () => {
        setHelpSection({ section: HelpSections.WHAT_IS, itemId: null });
        setIsExtendedHelp(false);
    };

    const openHelp = () => {
        setHelpSection({ section: HelpSections.WHAT_IS, itemId: null });
        setIsExtendedHelp(true);
    };

    useEffect(() => {
        if (helpSection.itemId) setIsExtendedHelp(true);
    }, [helpSection.itemId]);

    useEffect(() => {
        if (route !== RoutePath.HOME) return void setIsExtendedHelp(false);
        if (!firstLoad) return;
        const showHelp = localStorage.getItem('show_help');
        if (showHelp) {
            const showHelpValue = JSON.parse(showHelp);
            setShowAgain(showHelpValue);
            showHelpValue && openHelp();
        } else closeHelp();
        setFirstLoad(false);
    }, [firstLoad, route]);

    const showAgainSwitch = () => {
        setShowAgain((prev) => !prev);
        const showHelp = localStorage.getItem('show_help');
        if (showHelp) {
            const showHelpValue = JSON.parse(showHelp);
            localStorage.setItem('show_help', JSON.stringify(!showHelpValue));
        }
    }

    useEffect(() => localStorage.setItem('dont_show_help', JSON.stringify(showAgain)), [showAgain]);

    useEffect(() => closeHelp(), [route]);

    useEffect(() => {
        if (isScrolling) setHint(null);
    }, [isScrolling]);

    const handleScroll = () => {
        const currentListRef = extendedHelpRef.current;
        if (currentListRef) {
            const scrollTop = currentListRef.scrollTop;
            const scrollHeight = currentListRef.scrollHeight;
            const clientHeight = currentListRef.clientHeight;
            const isFullyScrolled = scrollTop + clientHeight >= scrollHeight;
            setHasScroll(!isFullyScrolled);
        }
    };

    useEffect(() => {
        const currentListRef = extendedHelpRef.current;
        if (currentListRef) {
            const hasScroll = currentListRef.scrollHeight > currentListRef.clientHeight;
            setHasScroll(hasScroll);
            if (hasScroll) {
                currentListRef.addEventListener('scroll', handleScroll);
            }
            return () => {
                currentListRef.removeEventListener('scroll', handleScroll);
            };
        }
    }, [helpSection]);

    return (
        <div>
            <div className="help__high-light" id="help">
                <div
                    className="help_icon"
                    onClick={openHelp}
                    onMouseEnter={() => setHint({ id: 'help' })}
                    onMouseLeave={() => setHint(null)}
                >
                    <div className="help_icon__question_mark" />
                </div>
            </div>
            <div className={`extended_help-wrapper ${isExtendedHelp ? 'open' : ''}`} key={helpSection.section}>
                <div className="extended_help" ref={extendedHelpRef}>
                    <div className="extended_help-box">
                        {helpSection.section === HelpSections.WHAT_IS && <WhatIsSection launchTutorial={() => null} />}
                        {helpSection.section === HelpSections.CHOOSING_IMAGE && <ChoosingImageSection />}
                        {helpSection.section === HelpSections.MASK_SELECTION && <MaskSelectionSection />}
                        {helpSection.section === HelpSections.RENDERING && <RenderingSection />}
                        {helpSection.section === HelpSections.INTERFACE && <InterfaceSection />}
                        {helpSection.section === HelpSections.CREATE_MASKS && <CreateMaskSection />}
                        {helpSection.section === HelpSections.DESCRIBE && <DescribeObjectsSection />}
                        {helpSection.section === HelpSections.GROUP && <GroupSection />}
                        {helpSection.section === HelpSections.ACCOUNT && <AccountSection />}
                        <ReadMore />
                    </div>
                    <div className="extended_help-bttns_wrapper">
                        <div className="dont_show">
                            <div className="dont_show_checkbox" onClick={() => showAgainSwitch()}>
                                {!showAgain && <div className="dont_show_checkbox-arrow" />}
                            </div>
                            <p>Don't show at launch</p>
                        </div>
                        <div className="extended_help-bttns">
                            <div className={`extended_help-bttns_scroll ${hasScroll ? 'active' : ''}`}
                                id="help-scroll_down"
                                onClick={handleScrollDown}
                                onMouseEnter={() => setHint({ id: 'help-scroll_down' })}
                                onMouseLeave={() => setHint(null)}>
                                <div className="scroll_arrow_down-icon" />
                            </div>
                            <div className="extended_help-bttns_close"
                                onClick={() => closeHelp()}
                                id="collapse"
                                onMouseEnter={() => setHint({ id: 'collapse' })}
                                onMouseLeave={() => setHint(null)}>
                                <div className="double_arrow_right-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ReadMore() {
    const helpSection = useStore($helpSection)
    return (
        <div className="extended_help-read_more">
            <p className="section_title">Read more</p>
            <div className="read_more_wrapper">
                {readMoreItems.map((item) => (
                    helpSection.section !== item.type &&
                    <div className="read_more-item" key={item.type} onClick={() => setHelpSection({ section: item.type, itemId: null })}>
                        <div className={`read_more-item-icon ${item.type.toLocaleLowerCase()}_icon`} />
                        <p>{item.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
