import { useCallback, useEffect, useRef, useState } from 'react';
import { setShadow } from 'entities/everything';
import './Note.scss';


type NoteProps = {
    children: string;
    id: string;
    fn?: () => void;
};

export default function Note({ children, id, fn }: NoteProps) {
    const [zIndex, setZIndex] = useState<null | string>(null);
    const [opacity, setOpacity] = useState<null | string>(null);
    const [backgroundColor, setBackgroundColor] = useState<null | string>(null);
    const ref = useRef<HTMLSpanElement>(null);

    const show = useCallback((id: string) => {
        if (fn) fn();
        const element = document.getElementById(id);
        if (!element) return;
        const span = ref.current;
        if (!span) throw new Error('Ref is not set');

        if (element.style.zIndex) setZIndex(element.style.zIndex);
        if (element.style.opacity) setOpacity(element.style.opacity);
        if (element.style.backgroundColor) setBackgroundColor(element.style.backgroundColor);
        element.style.zIndex = '101';
        element.style.opacity = '1';
        element.style.backgroundColor = 'white'
        setShadow(id);
    }, [fn]);

    const hide = useCallback((id: string) => {
        setShadow(null);
        const element = document.getElementById(id);
        if (!element) return;

        if (!zIndex) element.style.zIndex = '';
        else element.style.zIndex = zIndex;
        if (!opacity) element.style.opacity = '';
        else element.style.opacity = opacity;
        if (!backgroundColor) element.style.backgroundColor = '';
        else element.style.backgroundColor = backgroundColor;
    }, [zIndex, opacity, backgroundColor]);

    useEffect(() => () => hide(id), [id, hide]);

    return (<span className="note" ref={ref} onMouseEnter={() => show(id)} onMouseLeave={() => hide(id)}>{children}</span>);
}
