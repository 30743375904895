import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowSVG } from './icons/arrow.svg';
import './SelectForm.scss';


type SelectFormProps = {
    placeholder: string;
    options: Array<string>;
    state: string;
    setState: (value: string) => void;
    checked: boolean;
    className?: string;
    lookup?: boolean;
};

export default function SelectForm({ placeholder, options, state, setState, checked, className = '', lookup }: SelectFormProps) {
    const element = useRef<HTMLDivElement>(null);
    const list = useRef<HTMLDivElement>(null);
    const [opened, setOpened] = useState(false);
    const [errored, setErrored] = useState(false);
    const [letter, setLetter] = useState('');

    const select = useCallback((option: string) => setState(option), [setState]);

    useEffect(() => void (checked && setErrored(!state)), [checked, state]);

    const onClick = useCallback(() => setOpened(prev => {
        if (!prev) element.current?.focus();
        else element.current?.blur();
        return !prev;
    }), []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (opened && list.current) {
                if (/^[a-zA-Zа-яА-Я]$/.test(event.key)) setLetter(event.key);
                if (event.key === 'Backspace') setLetter('');
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [opened, options, letter, select]);

    useEffect(() => setLetter(''), [opened]);

    return (
        <div ref={element} className={classNames({ 'select-form': true, [className]: Boolean(className) })} tabIndex={0} onBlur={() => setOpened(false)}>
            <div className={classNames({ 'select-form__input': true, 'select-form__input_errored': errored })} onClick={() => onClick()}>
                {opened && lookup && <div className="select-form__lookup">{letter.toUpperCase()} |</div>}
                {!state && !(lookup && opened) && <div className="select-form__placeholder">{placeholder}</div>}
                {state && <div className="select-form__value">{state}</div>}
                <div className="select-form__arrow-container">
                    <ArrowSVG className={classNames({ pointer: true, 'select-form__arrow_rotated': opened })} />
                </div>
                <div className="select-form__options" ref={list}>
                    {options.filter(option => option.startsWith(letter.toUpperCase())).map(option => (<div className="select-form__option" onClick={() => select(option)} key={option}>
                        <div>{option}</div>
                        {state === option && <div className="select-form__option-selected-icon" />}
                    </div>))}
                </div>
            </div>
            <div className={classNames({ 'select-form__error': true, 'select-form__error_visible': errored })}>Please select an option for this field</div>
        </div>
    );
}
