import { useCallback, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { Attachment, AttachmentProps, AttachmentType, Group, Segment, useElement, useManager } from 'entities/sketch/Manager';
import { $models } from 'entities/library';
import { Model } from 'services/library';
import { Tab } from '../Attachments';
import Button from 'Components/Button';
import { setHelpSection } from 'entities/help';
import { HelpSections } from 'Components/Help/helpers/helpers';
import './Products.scss';


type Props = {
    element: Segment | Group;
    setTab: (tab: Tab) => void;
};

enum Type {
    PRODUCTS = 'PRODUCTS',
    CONTEXT = 'CONTEXT',
}

export default function Products({ element, setTab }: Props) {
    const manager = useManager('self');
    const models = useStore($models);
    const [type, setType] = useState(Type.PRODUCTS);
    const attachment = useElement(element, 'attachment');

    function selectModel(model: Model) {
        const attachmentProps: AttachmentProps = {
            filename: model.id,
            originalname: model.name,
            note: `${model.trigger_word} ${model.description}`,
            type: AttachmentType.MODEL,
        };

        element.attach(new Attachment(attachmentProps));
        manager.selectOneElement(element);
    }

    const scrollToModel = useCallback((id: string) => {
        const container = document.getElementById('products__grid');
        const element = document.getElementById(id + '_model');
        if (!element) throw new Error('Element not found');
        if (container && element) {
            container.scrollTo({
                top: element.offsetTop - container.offsetTop,
                behavior: 'smooth',
            });
        }
        element.style.borderColor = 'black';
        setTimeout(() => element.style.borderColor = '#dad9d9', 3500);
    }, []);

    return (
        <div className="products__attach-wrapper">
            <div className="products__norma">
                <div className="products__norma-icon" />
                <div className="products__norma-text">
                    <div>Try adding objects that the artificial intelligence of io-buro knows.</div>
                    <div>Only 1 item from the Catalog per masked object.</div>
                </div>
                <div className="products__norma-read_more" onClick={() => setHelpSection({ section: HelpSections.DESCRIBE, itemId: 'help-section__product' })}>
                    <div className="products__norma-read_more-text">READ MORE</div>
                    <div className="products__norma-read_more-icon" />
                </div>
            </div>
            <div className="products__rounds">
                <div className="products__round">
                    <div className={classNames('products__round-icon', { 'products__round-icon_active': type === Type.PRODUCTS })} onClick={() => setType(Type.PRODUCTS)} />
                    <div className="products__round-text">Products</div>
                </div>
                <div className="products__round">
                    <div className={classNames('products__round-icon', { 'products__round-icon_active': type === Type.CONTEXT })} onClick={() => setType(Type.CONTEXT)} />
                    <div className="products__round-text">Context</div>
                </div>
            </div>
            <div className="products__applied-item-container">
                <div className="products__applied-item-title">APPLIED ITEM</div>
                {
                    attachment
                        ?
                        <div className="products__applied-item">
                            <div className="products__applied-item-name" onClick={() => scrollToModel(attachment.filename)}>{attachment.originalname}</div>
                            <div className="products__applied-item-remove-icon" />
                        </div>
                        :
                        <div className="products__applied-item-empty">Empty</div>
                }
            </div>
            <div className="products__grid" id="products__grid">
                {
                    models.filter(model => model.status === 'succeeded').map(model => (
                        <div key={model.id} id={model.id + '_model'} className="products__model">
                            <div className="products-attach__model-image-wrapper">
                                <img
                                    src={`https://quarters-dev.site/api/model_preview/${model.id}.jpg`}
                                    alt={model.name.slice(0, -36)}
                                    className="products-attach__model-image"
                                />
                            </div>
                            <div className="products-attach__model-info-wrapper">
                                <div className="products-attach__model-description">{model.name}</div>
                                <div className="products-attach__model-row">
                                    <div className="products-attach__model-designed">
                                        <div className="model-designed_by">Designed by</div>
                                        <p className="products-attach__model-title">Audo Copenhage</p>
                                    </div>
                                    <Button color="dark" size="medium" disabled={attachment?.filename === model.id} onClick={() => selectModel(model)}>APPLY</Button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
