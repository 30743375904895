import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import * as R from 'ramda';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { $email, $userData, deleteUserFx, logoutFx, magicAuthFx, updateUserDataFx } from 'entities/user';
import { magic } from 'pages/Auth/Auth';
import Input from 'Components/Input';
import { SavingState, setSavingState } from 'entities/saving';
import Button from 'Components/Button';
import './Account.scss';


enum EmailChangingState {
    NONE = 'NONE',
    CHANGING = 'CHANGING',
    CHANGED = 'CHANGED',
}

export default function Account() {
    const userData = useStore($userData);
    const email = useStore($email);
    const [emailIsChanging, setEmailIsChanging] = useState(EmailChangingState.NONE);
    const [inputEmail, setInputEmail] = useState(email || '');
    const [businessName, setBusinessName] = useState(userData.businessName);
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [businessWebsiteLink, setBusinessWebsiteLink] = useState(userData.businessWebsiteLink);
    const [newsLetter, setNewsLetter] = useState<boolean>(true);

    const updateUserData = useCallback(() => {
        const newData = {
            firstName,
            lastName,
            businessName,
            profession: userData.profession,
            businessPhone: userData.businessPhone,
            businessEmail: userData.businessEmail,
            businessWebsiteLink,
            teamSize: userData.teamSize,
            linkedin: userData.linkedin,
            earlyDesignSoftware: userData.earlyDesignSoftware,
            subscribeNewsletter: newsLetter,
        };
        if (R.equals(newData, userData)) return;
        setSavingState(SavingState.SAVING);
        updateUserDataFx(newData).then(() => setSavingState(SavingState.SAVED));
    }, [firstName, lastName, businessName, businessWebsiteLink, userData, newsLetter]);

    const logout = useCallback(() => {
        logoutFx();
        setLeftSidePanel(LeftSidePanel.NONE);
    }, []);

    const close = useCallback(() => {
        setLeftSidePanel(LeftSidePanel.NONE);
    }, []);

    const signInWithMagic = useCallback(async () => {
        setEmailIsChanging(EmailChangingState.CHANGING);
        const token = await magic.auth.loginWithMagicLink({ email: inputEmail });
        if (!token) throw new Error('Token not exist.');
        magicAuthFx({ token });
    }, [inputEmail]);

    const changeNewsLetter = useCallback((value: boolean) => {
        const newData = {
            firstName,
            lastName,
            businessName,
            profession: userData.profession,
            businessPhone: userData.businessPhone,
            businessEmail: userData.businessEmail,
            businessWebsiteLink,
            teamSize: userData.teamSize,
            linkedin: userData.linkedin,
            earlyDesignSoftware: userData.earlyDesignSoftware,
            subscribeNewsletter: value,
        };

        setNewsLetter(value);
        setSavingState(SavingState.SAVING);
        updateUserDataFx(newData).then(() => setSavingState(SavingState.SAVED));
    }, [firstName, lastName, businessName, businessWebsiteLink, userData]);

    useEffect(() => {
        setEmailIsChanging(EmailChangingState.CHANGED);
        const timeout = setTimeout(() => setEmailIsChanging(EmailChangingState.NONE), 3000);

        return () => clearTimeout(timeout);
    }, [email]);

    return (
        <div className={classNames({ 'account__wrapper': true })}>
            <div className="account">
                <div className="account__exit-button" onClick={() => close()} />
                <div className="account__title">Account</div>
                <div className="account__content">
                    <div className="account__form">
                        <div className="account__form-label">COMPANY NAME</div>
                        <Input value={businessName} onChange={(e) => setBusinessName(e.currentTarget.value)} placeholder="Enter your company name" onBlur={updateUserData} />
                    </div>
                    <div className="account__form">
                        <div className="account__form-label">FIRST NAME</div>
                        <Input value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} placeholder="Enter your first name" onBlur={updateUserData} />
                    </div>
                    <div className="account__form">
                        <div className="account__form-label">LAST NAME</div>
                        <Input value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} placeholder="Enter your last name" onBlur={updateUserData} />
                    </div>
                    <div className="account__form">
                        <div className="account__form-label">EMAIL</div>
                        <div className="account__form-email-container">
                            <Input className="account__form-email" value={inputEmail} onChange={event => setInputEmail(event.target.value)} />
                            <Button size="medium" color="white" onClick={signInWithMagic}>UPDATE EMAIL</Button>
                        </div>
                        {emailIsChanging === EmailChangingState.CHANGING && <div className="account__email-note">Confirmation link sent to {inputEmail}</div>}
                        {emailIsChanging === EmailChangingState.CHANGED && <div className="account__email-note">Your email has been successfully updated</div>}
                    </div>
                    <div className="account__form">
                        <div className="account__form-label">COMPANY WEBSITE, LINKEDIN, OR SIMILAR</div>
                        <Input value={businessWebsiteLink} onChange={(e) => setBusinessWebsiteLink(e.currentTarget.value)} placeholder="Enter your website" onBlur={updateUserData} />
                    </div>
                    <div className="account__newsletter-form">
                        <div className="account__form-label">EMAIL</div>
                        <div className="account__newsletter-input">
                            <div className={classNames('account__newsletter-item', { 'account__newsletter-item_active': newsLetter })} onClick={() => changeNewsLetter(true)}>
                                <div className={classNames('account__newsletter-checkmark-icon', { 'account__newsletter-checkmark-icon_active': newsLetter })} />
                                <div className="account__newsletter-item-text">On</div>
                            </div>
                            <div className={classNames('account__newsletter-item', { 'account__newsletter-item_active': !newsLetter })} onClick={() => changeNewsLetter(false)}>
                                <div className={classNames('account__newsletter-checkmark-icon', { 'account__newsletter-checkmark-icon_active': !newsLetter })} />
                                <div className="account__newsletter-item-text">Off</div>
                            </div>
                        </div>
                    </div>
                    <div className="account__button" onClick={() => logout()}>
                        <div className="account__logout-icon" />
                        <div className="account__button-text">LOG OUT</div>
                    </div>
                    <DeletingAccount />
                </div>
            </div>
        </div >
    );
}

function DeletingAccount() {
    const [deleting, setDeleting] = useState(false);

    const deleteUser = useCallback(() => deleteUserFx().then(() => window.location.href = 'https://quarters-dev.site'), []);

    if (deleting) return (
        <div className="account__deleting">
            <div className="account__deleting-title">Are you sure you want to delete your account?</div>
            <div className="account__deleting-buttons">
                <Button size="medium" color="white" onClick={() => deleteUser()}>DELETE</Button>
                <Button size="medium" color="white" onClick={() => setDeleting(false)}>CANCEL</Button>
            </div>
        </div>
    );

    else return (
        <div className="account__button account-button-delete" onClick={() => setDeleting(true)}>
            <div className="account__delete-icon" />
            <div className="account__button-text">DELETE ACCOUNT</div>
        </div>
    );
}