import React, { createElement } from 'react';
import { useStore } from 'effector-react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { $modal, ModalType } from 'entities/modal';
import { $route, RoutePath } from 'entities/router';
import { $leftSidePanel, LeftSidePanel } from 'entities/leftSidePanel';
import Sketch from './Sketch';
import Auth from './Auth';
import Home from './Home';
import SubmitForRendering from './SubmitForRendering';
import Account from './Account';
import { $hint } from 'entities/hint';
import Hint from 'Components/Hint/Hint';
import Share from 'Components/Share';
import Help from 'Components/Help';
import Saving from './Account/Saving';
import IrreversibleStep from './leftSidePanel/IrreversibleStep';
import Shadow from './Shadow';
import { $tooltip } from 'entities/rooltip';
import { $shadow } from 'entities/everything';
import MainNotice from './Sketch/MainNotice';
import Generator from './Generator';
import Notification from 'Components/Notifications';
import Approve from './Approve';

Bugsnag.start({
    apiKey: 'be730aa3eeb52b63552a169f14df1219',
    plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: 'be730aa3eeb52b63552a169f14df1219' });
const plugin = Bugsnag.getPlugin('react');
const ErrorBoundary = plugin ? plugin.createErrorBoundary(React) : ({ children }: { children: React.ReactNode }) => <>{children}</>;


export default function Router() {
    const route = useStore($route);
    const modal = useStore($modal);
    const shadow = useStore($shadow);
    const leftSidePanel = useStore($leftSidePanel);
    const hint = useStore($hint);
    const tooltip = useStore($tooltip);

    return (
        <ErrorBoundary>
            {leftSidePanel === LeftSidePanel.AUTH && <Auth />}
            {leftSidePanel === LeftSidePanel.IRREVERSIBLE_STEP && <IrreversibleStep />}
            {leftSidePanel === LeftSidePanel.ACCOUNT && <Account />}
            {leftSidePanel === LeftSidePanel.SUBMIT_FOR_RENDERING && <SubmitForRendering />}

            {route === RoutePath.HOME && <Home />}
            {route === RoutePath.SKETCH && <Sketch />}
            {route === RoutePath.GENERATOR && <Generator />}
            {route === RoutePath.APPROVE && <Approve />}

            {modal.type === ModalType.SHARE && <Share {...modal.props} />}
            {tooltip && <>{createElement(tooltip.element, { parent: tooltip.parent })}</>}

            <Saving />
            <Notification />

            <Shadow />
            {shadow === 'NOTIFY' && <MainNotice />}
            {hint && <Hint hint={hint} />}
            <Help />
        </ErrorBoundary>
    );
}
