import { sample } from 'effector';
import * as R from 'ramda';
import { $userId, googleAuthFx } from 'entities/user';
import { closeSketch, goTo, popstate, setRightSide } from './action';
import { $rightSide, $route, RoutePath } from './store';
import { closeModal } from 'entities/modal';

$rightSide.on(setRightSide, R.nthArg(1));

sample({
    clock: popstate,
    source: $userId,
    filter: () => Boolean(window.location.search),
    fn: () => {
        const code = new URLSearchParams(window.location.search).get('code');
        const jsonState = new URLSearchParams(window.location.search).get('state');
        window.history.replaceState(null, '', window.location.origin);
        if (!jsonState) throw new Error('State not exist.');
        const state = JSON.parse(jsonState);
        if (!code) throw new Error('Code not exist.');
        return { code, ...state };
    },
    target: googleAuthFx,
});

sample({
    clock: goTo,
    filter: value => value === RoutePath.HOME,
    fn: () => window.location.reload(),
});

sample({
    clock: goTo,
    filter: value => value !== RoutePath.HOME,
    target: $route,
});

sample({
    clock: popstate,
    filter: () => globalThis.location.pathname === '/gen',
    fn: () => RoutePath.GENERATOR,
    target: $route,
});

sample({
    clock: popstate,
    filter: () => globalThis.location.pathname === '/approve',
    fn: () => RoutePath.APPROVE,
    target: $route,
});

closeSketch.watch(closeModal);

window.addEventListener('load', popstate);

window.addEventListener('popstate', popstate);