import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import * as Listeners from 'entities/sketch/Listeners';
import { Segment, useManager, Group as GroupClass, useInitiator, ManagerInitStep } from 'entities/sketch/Manager';
import SegmentElement from './SegmentElement';
import { ReactComponent as CheckboxSmallCheckedSVG } from './icons/checkbox_small_checked.svg';
import { ReactComponent as InpaintingActiveSVG } from './icons/inpainting_active.svg';
import { ReactComponent as InpaintingSVG } from './icons/inpainting.svg';
import { ReactComponent as InpaintingCloseSVG } from './icons/inpainting_close.svg';
import { ReactComponent as CheckboxSmallSVG } from './icons/checkbox_small.svg';
import { ReactComponent as MatchDefinitionSVG } from './icons/match_definition.svg';
import { ReactComponent as UndefineSVG } from './icons/undefine.svg'
import { ReactComponent as DeleteSVG } from './icons/delete.svg';
import { ReactComponent as DefinitionSVG } from './icons/definition.svg';
import { GroupElement } from './GroupElement';
import { setHint } from 'entities/hint';
import { useHotKey } from 'hooks';
import { $elementForAttach } from 'entities/library';
import { $shadow, Highlight } from 'entities/everything';
import Attachments from '../Attachments';
import useScrollDetection from 'services/useScrollDetection';
import { EditGroupButton } from './Buttons';
import Button from 'Components/Button';
import { $notificationType, NotificationType } from 'entities/notification';
import './List.scss';
import Skeleton from './Skeleton';


export enum ListType {
    REGULAR = 'REGULAR',
    INPAINT = 'INPAINT',
    SKELETON = 'SKELETON',
}

export default function List() {
    const shadow = useStore($shadow);
    const notificationType = useStore($notificationType);
    const list = useManager('list');
    const selectedElements = useManager('selectedElements');
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const inpaintingQueue = useManager('inpaintingQueue');
    const listener = useManager('listener');
    const initStep = useInitiator('initStep');
    const [matchDefinition, setMatchDefinition] = useState<((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void) | undefined>(undefined);
    const listRef = useRef<HTMLDivElement>(null);
    const isScrolling = useScrollDetection({ ref: listRef });
    const [listType, setListType] = useState<ListType>(ListType.REGULAR);

    const deleteDisabled = useMemo(() => selectedElements.some(element => element instanceof Segment && element.groupId) || selectedElements.length === 0, [selectedElements]);
    const undefineDisabled = useMemo(() => selectedElements.length === 0 || selectedElements.some(element => element instanceof Segment && element.groupId), [selectedElements]);
    const elementForAttach = useStore($elementForAttach);

    useEffect(() => {
        if (listener instanceof Listeners.MatchDefinition) setMatchDefinition(() => (e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, value: string) => listener.onClick(e, value));
        else setMatchDefinition(undefined);
    }, [listener]);

    const handleDelete = useCallback(async () => deleteDisabled || manager.removeElements(selectedElements), [selectedElements, manager, deleteDisabled]);

    const undefine = useCallback(() => {
        if (undefineDisabled) return;
        manager.undefine(selectedElements);
    }, [manager, selectedElements, undefineDisabled]);

    const matchDefinitionClick = useCallback(() => {
        if (listener instanceof Listeners.MatchDefinition) manager.matchDefinition = null;
        else manager.listener = new Listeners.MatchDefinition(manager);
    }, [manager, listener]);

    const setSelectListener = useCallback(() => manager.listener = new Listeners.Select(manager), [manager]);
    const escapeMatchDefinitionOptions = useMemo(() => ({ condition: listener instanceof Listeners.MatchDefinition }), [listener]);
    const deleteSegmentsOption = useMemo(() => ({ condition: notificationType !== NotificationType.JOIN_MASKS }), [notificationType]);

    useHotKey('Delete', handleDelete, deleteSegmentsOption);
    useHotKey('Escape', setSelectListener, escapeMatchDefinitionOptions);

    const handleDragEndRegular = useCallback(async (event: DropResult) => {
        if (!event.destination) return;
        if (event.destination.index === event.source.index) return;

        if (!editingGroup) return manager.changeOrder(event.source.index, event.destination.index);

        const draggableElement = [...manager.groups, ...manager.segments].find(element => element.id === event.draggableId);
        if (!draggableElement) throw new Error('Drag error.');

        const groupIndex = list.findIndex(element => element === editingGroup);
        const term = event.source.index < groupIndex ? 1 : 0;
        const toGroup = event.destination.index + term > groupIndex && event.destination.index <= (groupIndex + editingGroup.segments.length);
        if (toGroup) {
            const term = event.source.index > groupIndex ? -1 : 0;
            const fromGroup = event.source.index > groupIndex && event.source.index <= (groupIndex + editingGroup.segments.length);
            if (fromGroup) return editingGroup.changeSegmentPosition(event.source.index - groupIndex - 1, event.destination.index - groupIndex - 1);
            else {
                if (draggableElement instanceof GroupClass) return;
                return manager.addSegmentToGroup(draggableElement, editingGroup, event.destination.index - groupIndex + term);
            }
        } else {
            const term = event.destination.index <= groupIndex ? -1 : 0;
            const sourceIndex = event.source.index < groupIndex ? event.source.index : (event.source.index - editingGroup.segments.length);
            const destinationIndex = event.destination.index <= groupIndex ? event.destination.index : (event.destination.index - editingGroup.segments.length);
            const fromGroup = event.source.index > groupIndex && event.source.index <= (groupIndex + editingGroup.segments.length);
            if (fromGroup) {
                if (draggableElement instanceof GroupClass) return;
                manager.removeSegmentFromGroup(draggableElement, editingGroup, destinationIndex + 1 + term);
            }
            else return manager.changeOrder(sourceIndex, destinationIndex);
        }
    }, [manager, editingGroup, list]);

    const changeListType = useCallback(() => setListType(prev => {
        if (prev === ListType.REGULAR) return ListType.INPAINT;
        if (prev === ListType.INPAINT) return ListType.REGULAR;
        throw new Error('Unknown list type.');
    }), []);

    const handleDragEndInpaint = useCallback(async (event: DropResult) => {
        if (!event.destination) return;
        if (event.destination.index === event.source.index) return;
        const queue = manager.inpaintingQueue.slice();
        const draggableElement = queue.splice(event.source.index, 1)[0];
        queue.splice(event.destination.index, 0, draggableElement);
        manager.inpaintingQueue = queue;
    }, [manager]);

    const handleDragEnd = useMemo(() => {
        if (listType === ListType.REGULAR) return handleDragEndRegular;
        if (listType === ListType.INPAINT) return handleDragEndInpaint;
        if (listType === ListType.SKELETON) return () => null;
        throw new Error('Unknown list type.');
    }, [listType, handleDragEndRegular, handleDragEndInpaint]);

    const regularList = useMemo(() => {
        let counter = 0;
        return list.map((element, index) => {
            if (element instanceof Segment) return (<SegmentElement matchDefinition={matchDefinition} segment={element} index={index + counter} key={element.id} listType={ListType.REGULAR} />);

            if (element instanceof GroupClass) {
                if (element === editingGroup) {
                    counter += element.segments.length;
                    return (<GroupElement matchDefinition={matchDefinition} group={element} index={index + counter - element.segments.length} key={element.id} listType={ListType.REGULAR} />);
                }
                return (<GroupElement matchDefinition={matchDefinition} group={element} index={index + counter} key={element.id} listType={ListType.REGULAR} />);
            }
            return null;
        })
    }, [list, editingGroup, matchDefinition]);

    const inpaintList = useMemo(() => inpaintingQueue.map(({ element }, index) => {
        if (element instanceof Segment) return (<SegmentElement matchDefinition={matchDefinition} segment={element} index={index} key={element.id} listType={ListType.INPAINT} />);
        if (element instanceof GroupClass) return (<GroupElement matchDefinition={matchDefinition} group={element} index={index} key={element.id} listType={ListType.INPAINT} />);
        throw new Error('Unknown element.');
    }), [inpaintingQueue, matchDefinition]);

    const skeletonList = useMemo(() => Array.from({ length: 4 }, (_, index) => <Skeleton key={index} />), []);

    useEffect(() => {
        if (isScrolling) setHint(null);
    }, [isScrolling]);

    useEffect(() => {
        if (initStep === ManagerInitStep.RECOGNIZING) setListType(ListType.SKELETON);
        else setListType(prev => {
            if (prev === ListType.SKELETON) return ListType.REGULAR;
            else return prev;
        });
    }, [initStep])

    return (
        <div className="list_wrapper">
            {initStep === ManagerInitStep.RECOGNIZING && <Recognizing />}
            <Attachments element={elementForAttach} />
            <div className={classNames({ 'list': true, 'list_scrolling': isScrolling })} ref={listRef}>
                <div className="list__toolbar" id="list__toolbar">
                    <EditGroupButton />
                    <div className="list__toolbar-gap" />
                    <div
                        className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_selected': listener instanceof Listeners.MatchDefinition })}
                        onClick={matchDefinitionClick}
                    >
                        <MatchDefinitionSVG />
                        <div className="list__toolbar-button-text">MATCH<div />DEFINITION</div>
                    </div>
                    <div className="list__toolbar-gap" />
                    <div
                        id="undefine"
                        className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': undefineDisabled })}
                        onClick={undefine}
                        onMouseEnter={() => setHint({ id: 'undefine' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <UndefineSVG />
                        <div className="list__toolbar-button-text">UNDEFINE</div>
                    </div>
                    <div className="list__toolbar-gap" />
                    <div
                        id="delete"
                        className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': deleteDisabled || editingGroup })}
                        onClick={handleDelete}
                        onMouseEnter={() => setHint({ id: 'delete' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <DeleteSVG />
                        <div className="list__toolbar-button-text">DELETE</div>
                    </div>
                    <div className="list__toolbar-big-gap" />
                    <Button size="medium" color="white" className="list__export-button" onClick={() => manager.saveRender()}>
                        <div className="list__export-button-icon" />
                        <div>EXPORT</div>
                    </Button>
                </div>
                <div className={classNames({ list__head: true, highlighted: shadow === Highlight.NOTIFY })}>
                    <Checkbox />
                    <div className="list__head-hash-cell" id="grid">#</div>
                    <div className="list__head-inpaint" onClick={changeListType}>
                        {listType === ListType.REGULAR && (inpaintingQueue.length ? <InpaintingActiveSVG /> : <InpaintingSVG />)}
                        {listType === ListType.INPAINT && <InpaintingCloseSVG />}
                    </div>
                    <div className="list__head-definition-cell">
                        <DefinitionSVG />
                        <span>DEFINITION</span>
                    </div>
                </div>
                <div className={classNames({ list__table: true, highlighted: shadow === Highlight.NOTIFY })}>
                    <div className="list__table-elements-wrapper">
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="single">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {listType === ListType.REGULAR && regularList}
                                        {listType === ListType.INPAINT && inpaintList}
                                        {listType === ListType.SKELETON && skeletonList}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div >
    );
}

function Checkbox() {
    const list = useManager('list');
    const selectedElements = useManager('selectedElements');
    const manager = useManager('self');

    const partSelected = useMemo(() => selectedElements.length > 0 && selectedElements.length < list.length, [selectedElements, list]);
    const allSelected = useMemo(() => selectedElements.length === list.length, [selectedElements, list]);

    const handleClick = useCallback(() => {
        if (allSelected) return manager.unselectAllElements();
        if (partSelected) return manager.unselectAllElements();
        manager.selectElements(list);
    }, [partSelected, allSelected, manager, list]);

    return (
        <div
            className="list__head-checkbox-cell"
            id="checkbox"
            onMouseEnter={() => setHint({ id: partSelected || allSelected ? 'checkbox_selected' : 'checkbox' })}
            onMouseLeave={() => setHint(null)}>
            {
                partSelected || allSelected
                    ?
                    <CheckboxSmallCheckedSVG className="pointer" onClick={handleClick} />
                    :
                    <CheckboxSmallSVG className="pointer" onClick={handleClick} />
            }
        </div>
    );
}

function Recognizing() {
    return (
        <div className="recognizing__background">
            <div className="recognizing__loader" />
        </div >
    );
}
