import { useCallback, useEffect, useRef, useState } from 'react';
import { setShadow } from 'entities/everything';
import { setTooltip } from 'entities/rooltip';
import { IdentifyButtonTooltip } from 'pages/Sketch/LeftBar/LeftBar';
import './NoteIdentifyButtonWithTooltip.scss';


type NoteProps = {
    children: string;
};

export default function NoteIdentifyButtonWithTooltip({ children }: NoteProps) {
    const [zIndex, setZIndex] = useState<null | string>(null);
    const [opacity, setOpacity] = useState<null | string>(null);
    const ref = useRef<HTMLSpanElement>(null);

    const show = useCallback((id: string) => {
        const element = document.getElementById('identify_button');
        if (!element) return;

        setTooltip({ element: IdentifyButtonTooltip, parent: element });

        const span = ref.current;
        if (!span) throw new Error('Ref is not set');

        if (element.style.zIndex) setZIndex(element.style.zIndex);
        if (element.style.opacity) setOpacity(element.style.opacity);
        element.style.zIndex = '101';
        element.style.opacity = '1';
        setShadow(id);
    }, []);

    const hide = useCallback((id: string) => {
        setShadow(null);
        const element = document.getElementById('identify_button');
        if (!element) return;
        setTooltip(null);

        if (!zIndex) element.style.zIndex = '';
        else element.style.zIndex = zIndex;
        if (!opacity) element.style.opacity = '';
        else element.style.opacity = opacity;
    }, [zIndex, opacity]);

    useEffect(() => () => hide('identify_button'), [hide]);

    return (<span className="note-identify-button-with-tooltip" ref={ref} onMouseEnter={() => show('identify_button')} onMouseLeave={() => hide('identify_button')}>{children}</span>);
}
