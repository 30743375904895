import './Skeleton.scss';


export default function Skeleton() {
    return (
        <div className="skeleton">
            <div className="skeleton__dnd-cell">
                <div className="skeleton__dnd-icon" />
            </div>
            <div className="skeleton__check-box-cell">
                <div className="skeleton__check-box-icon" />
            </div>
        </div>
    );
}