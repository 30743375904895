import ReactDOM from 'react-dom/client';
import './entities';
import Router from 'pages';
import './index.scss'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<Router />);

console.log('version: ', process.env.REACT_APP_VERSION);