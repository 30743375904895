import { createEffect, createStore } from 'effector';
import * as R from 'ramda';
import { login, googleAuth, magicAuth, UserData, updateUserData, logout, deleteUser } from 'services/user';


export const $email = createStore<string | null>(null);
export const $userId = createStore<string>('');
export const $userData = createStore<Omit<UserData, 'id' | 'email'>>(new UserData());

export const googleAuthFx = createEffect(googleAuth);
export const magicAuthFx = createEffect(magicAuth);
export const loginFx = createEffect(login);
export const updateUserDataFx = createEffect(updateUserData);
export const logoutFx = createEffect(logout);
export const deleteUserFx = createEffect(deleteUser);

$email
    .on(googleAuthFx.doneData, R.pipe(R.nthArg(1), R.prop('email')))
    .on(magicAuthFx.doneData, R.pipe(R.nthArg(1), R.prop('email')))
    .on(loginFx.doneData, R.pipe(R.nthArg(1), R.prop('email')))
    .on(logoutFx.doneData, R.always(null));

$userId
    .on(googleAuthFx.doneData, R.pipe(R.nthArg(1), R.prop('id')))
    .on(magicAuthFx.doneData, R.pipe(R.nthArg(1), R.prop('id')))
    .on(loginFx.doneData, R.pipe(R.nthArg(1), R.prop('id')))
    .on(logoutFx.doneData, R.always(''));

$userData
    .on(googleAuthFx.doneData, R.nthArg(1))
    .on(magicAuthFx.doneData, R.nthArg(1))
    .on(loginFx.doneData, R.nthArg(1))
    .on(updateUserDataFx.done, R.pipe(R.nthArg(1), R.prop('params')))
    .on(logoutFx.doneData, R.construct(UserData));

googleAuthFx.doneData.watch(auth);
magicAuthFx.doneData.watch(auth);
logoutFx.doneData.watch(loginFx);

function auth() {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState({}, '', url.toString());
}

loginFx();
